import React, { useContext, useEffect, useState } from "react";
import "./affi-events.css";
import { getEvents } from "../../../../../utils/apis/events/event";
import { Context } from "../../../../../utils/context";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import AffiliateEvents from "./AffiliateEvents/AffiliateEvents";
import InfluencerEvent from "./InfluencerEvent/InfluencerEvent";

const Event = () => {


  const {userdata} = useContext(Context);
  return (
    <>
      <section className="afffievents-sec">
        <div className="row">
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <div className="advertmaintabs">
              <Nav variant="pills" className="flex-row ">
                <Nav.Item>
                  <Nav.Link eventKey="1">Affiliate</Nav.Link>
                </Nav.Item>

              {userdata?.influencer_open && userdata?.influencer_open?.status === 1 && (
                <Nav.Item>
                  <Nav.Link eventKey="2">Influencer</Nav.Link>
                </Nav.Item>
              )}
                

                {/* <FontAwesomeIcon icon="fa-solid fa-gear" className="settingIcon" onClick={() => setModalShow(true)} /> */}
                {/* <SettingModal show={modalShow}
                    onHide={() => setModalShow(false)} /> */}
              </Nav>
            </div>

            <Tab.Content>
              <Tab.Pane eventKey="1">
                <AffiliateEvents />
              </Tab.Pane>
              <Tab.Pane eventKey="2">
                <InfluencerEvent />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default Event;
