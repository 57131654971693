import React, { useContext } from "react";
import "./EventCard.css";
import { Context } from "../../../../../../utils/context";
import { formatDateExpire } from "../../../../../../utils/common";
import { Link } from "react-router-dom";

const EventCard = ({ events, taskDead }) => {
  const { IMG_URL } = useContext(Context);
  return (
    <div className="EventCard me-1">
      <h5 className="my-3">Events</h5>
      <div className="card_holder">
        {events?.map((value, index) => (
          <div className="card">
            <div className="img-div">
              {/* D:\React Projects\netpurti_seller_admin3\public\assest\images\Seller_panel\AffilateDashboard\Images\profile.png */}
              <img className="cardimg" src={IMG_URL + value?.image} />
            </div>
            <div className="cardbody">
              <p className="datep">
                {value?.start} - {value?.end}
              </p>
              <h6 className="cardtitle">{value?.title}</h6>
              <p className="like">
                {" "}
                <span>Type : {value?.event_type}</span>
                <span className="ps-2 pe-2">
                  |{" "}
                  {value?.event_type === "Online"
                    ? <Link target={new Date(value?.end) <= new Date() ? "" : "_blank"}  to={new Date(value?.end) <= new Date() ? '' : value?.event_link} className="btn btn-info">View Link</Link>
                    : value?.event_address}
                </span>
                <span></span>
              </p>
            </div>
          </div>
        ))}
      </div>

      <h5 className="my-3">Task Deadline</h5>
      <div className="card_holder">
        {taskDead?.map((value, index) => (
          <div className="card">
            <div className="cardbody">
              <p className="datep">Deadline Date :- {formatDateExpire(value?.createdAt,value?.validity)}</p>
              <h6 className="cardtitle">Task ID :- {value?.task_id}</h6>
              <p className="like">
                {" "}
                <span>Type : {value?.task_type}</span>
                <span className="ps-2 pe-2"></span>
                <span></span>
              </p>
            </div>
          </div>
        ))}

        
      </div>

      {/* <h5 className="my-3">New Task</h5>
      <div className="card_holder">
        <div className="card">
          <div className="cardbody">
            <p className="datep"> asdasdasdto dfasdasdasd</p>
            <h6 className="cardtitle">asdasdasd</h6>
            <p className="like">
              {" "}
              <span>Type : asdasdasd</span>
              <span className="ps-2 pe-2">|sadasdasdasdasd</span>
              <span></span>
            </p>
          </div>
        </div>
        <div className="card">
          <div className="cardbody">
            <p className="datep"> asdasdasdto dfasdasdasd</p>
            <h6 className="cardtitle">asdasdasd</h6>
            <p className="like">
              {" "}
              <span>Type : asdasdasd</span>
              <span className="ps-2 pe-2">|sadasdasdasdasd</span>
              <span></span>
            </p>
          </div>
        </div>
        <div className="card">
          <div className="cardbody">
            <p className="datep"> asdasdasdto dfasdasdasd</p>
            <h6 className="cardtitle">asdasdasd</h6>
            <p className="like">
              {" "}
              <span>Type : asdasdasd</span>
              <span className="ps-2 pe-2">|sadasdasdasdasd</span>
              <span></span>
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default EventCard;
