import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";

import { edit } from "../../../../../../utils/apis/seller/outer-website/masters/content-sections/content-sections";
import { getOuterSectionData } from "../../../../../../utils/apis/myWebsite/mywebsite";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, sellerDomain } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue,
  } = useForm();

  const imageFile = watch("image");
  const videoFile = watch("video");
  const logoFile = watch("rating_logo");

  const onSubmit = async (data) => {
    try {
      const sendData = new FormData();

      sendData.append("domain_id", sellerDomain?.value);
      sendData.append(
        "theme_content_section_id",
        data?.theme_content_section_id
      );
      sendData.append("section_type", section?.content_section?.id);

      if (
        section?.content_section?.id !== 6 &&
        section?.content_section?.id !== 7
      ) {
        sendData.append("title", data?.title);
      } else {
        if (section?.content_section?.id === 6) {
          for (let i = 1; i <= 2; i++) {
            sendData.append(`selector_title_${i}`, data[`selector_title_${i}`]);
            sendData.append(
              `selector_status_${i}`,
              data[`selector_status_${i}`]
            );
          }
        }
        if (section?.content_section?.id === 7) {
          for (let i = 1; i <= 5; i++) {
            sendData.append(`multi_title_${i}`, data[`multi_title_${i}`]);
            sendData.append(
              `multi_status_${i}`,
              data[`multi_status_${i}`]
            );
          }
        }
      }

      if (section?.content_section?.id === 1) {
        sendData.append("sub_title", data?.sub_title);
        sendData.append("video", data?.video[0]);
      }

      if (section?.content_section?.id === 2) {
        sendData.append("sub_title", data?.sub_title);

        sendData.append("image", data?.image[0]);
      }

      if (section?.content_section?.id === 4) {
        sendData.append("description", data?.description);
      }

      if (section?.content_section?.id === 5) {
        sendData.append("rating", data?.rating);
        sendData.append("sub_title", data?.sub_title);
        sendData.append("rating_logo", data?.rating_logo[0]);
      }

      const response = await edit(sendData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(getValues());

  const [section, setSection] = useState();
  const GetEditData = async () => {
    const response = await getOuterSectionData(id, sellerDomain?.value);

    if (response?.success) {
      if (response?.data?.section?.content_section_id != 6 && response?.data?.section?.content_section_id != 7) {
        reset(response?.data?.data);
      } else {
        const fieldTitle = response?.data?.section?.content_section_id == 6 ? 'selector' : 'multi';
        response?.data?.data?.map((value, index) => {
          setValue(`${fieldTitle}_title_${index + 1}`, value?.title);
          setValue(`${fieldTitle}_status_${index + 1}`, value?.status);
        });
      }
      setValue("theme_content_section_id", response?.data?.section?.id);
      setSection(response?.data?.section);
    }
  };
  useEffect(() => {
    GetEditData();
  }, []);

  const selector_status_1 = watch("selector_status_1");

  // Effect to set selector_status_2 to false if selector_status_1 is true
  useEffect(() => {
    if (selector_status_1) {
      setValue("selector_status_2", false); // Set selector_status_2 to false
    }
  }, [selector_status_1, setValue]);

  // Effect to set selector_status_1 to false if selector_status_2 is true
  const selector_status_2 = watch("selector_status_2");
  useEffect(() => {
    if (selector_status_2) {
      setValue("selector_status_1", false); // Set selector_status_1 to false
    }
  }, [selector_status_2, setValue]);

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Section
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={12}>
                {section?.content_section?.id !== 6 &&
                section?.content_section?.id !== 7 ? (
                  <div className="main-form-section mt-3">
                    <Form.Group>
                      <Form.Label>Title</Form.Label>

                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="title"
                          placeholder="Title"
                          className={classNames("", {
                            "is-invalid": errors?.title,
                          })}
                          {...register("title", {
                            required: "Title is required",
                          })}
                        />
                      </InputGroup>
                      {errors.title && (
                        <span className="text-danger">
                          {errors.title.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                ) : (
                  
                    <>
                      {(() => {
                        const fieldsCount = section?.content_section?.id === 6 ? 2 : 5; 
                        const fieldTitle = section?.content_section?.id === 6 ? 'selector' : 'multi';
                        const result = [];

                        // Loop through the number of fields you want (in this case, 2)
                        for (let i = 1; i <= fieldsCount; i++) {
                          result.push(
                            <div
                              className="main-form-section d-flex align-items-end mt-3"
                              key={i}
                            >
                              <Form.Group className="">
                                <div>
                                  <Form.Label>Title</Form.Label>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name={`${fieldTitle}_title_${i}`} 
                                      placeholder="Title"
                                      className={classNames("", {
                                        "is-invalid":
                                          errors?.[`${fieldTitle}_title_${i}`], 
                                      })}
                                      {...register(`${fieldTitle}_title_${i}`, {
                                        required: "Title is required",
                                      })}
                                    />
                                  </InputGroup>
                                  {errors?.[`${fieldTitle}_title_${i}`] && (
                                    <span className="text-danger">
                                      {errors?.[`${fieldTitle}_title_${i}`]?.message}
                                    </span>
                                  )}
                                </div>
                              </Form.Group>

                              <div className="form-check form-switch ms-5">
                                <Controller
                                  name={`${fieldTitle}_status_${i}`} // Directly use index for name
                                  control={control} // Pass the control from useForm
                                  defaultValue={i === 1 ? true : false} // Directly handle the default value using index
                                  render={({ field: controllerField }) => (
                                    <>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        {...controllerField} // Spread the field props to bind with React Hook Form
                                        checked={controllerField.value} // Ensure the checkbox reflects the correct state
                                      />
                                      <label className="form-check-label">
                                        {controllerField.value
                                          ? "Active"
                                          : "Inactive"}{" "}
                                        {/* Toggle label based on state */}
                                      </label>
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          );
                        }

                        return result;
                      })()}
                    </>
                  
                )}

                {section?.content_section?.id === 5 && (
                  <div className="main-form-section mt-3">
                    <Form.Group>
                      <Form.Label>Rating</Form.Label>

                      <InputGroup>
                        <Form.Control
                          as="select" // This turns the input into a dropdown
                          name="rating"
                          defaultValue="1" // Default value set to 1
                          className={classNames("", {
                            "is-invalid": errors?.rating,
                          })}
                          {...register("rating", {
                            required: "Rating is required",
                            min: {
                              value: 1,
                              message: "Rating must be at least 1",
                            },
                            max: {
                              value: 5,
                              message: "Rating must be at most 5",
                            },
                          })}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </Form.Control>
                      </InputGroup>
                      {errors.rating && (
                        <span className="text-danger">
                          {errors.rating.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                )}

                {(section?.content_section?.id === 1 ||
                  section?.content_section?.id === 2 ||
                  section?.content_section?.id === 5) && (
                  <div className="main-form-section mt-3">
                    <Form.Group>
                      <Form.Label>Sub Title</Form.Label>

                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="sub_title"
                          placeholder="Sub Title"
                          className={classNames("", {
                            "is-invalid": errors?.sub_title,
                          })}
                          {...register("sub_title", {
                            required: "Sub Title is required",
                          })}
                        />
                      </InputGroup>
                      {errors.sub_title && (
                        <span className="text-danger">
                          {errors.sub_title.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                )}

                {section?.content_section?.id === 1 && (
                  <div className="main-form-section mt-3">
                    <Row className="justify-content-start">
                      <Form.Label className="text-left">Video</Form.Label>

                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.video,
                          })}
                          type="file"
                          {...register("video", {
                            // validate: async (value) => {
                            //   if (typeof value !== "string") {
                            //     const fileTypes = ["jpg", "png", "jpeg"];
                            //     const fileType = value[0].name?.split(".")[1];
                            //     if (!fileTypes.includes(fileType)) {
                            //       return `please upload a valid file format. (${fileTypes})`;
                            //     }
                            //     const sizes = await getDimension(value[0]);
                            //     if (
                            //       sizes.width !== 420 &&
                            //       sizes.height !== 520
                            //     ) {
                            //       return "Video width and height must be 420 px and 520 px";
                            //     }
                            //     const fileSize = Math.round(
                            //       value[0].size / 1024
                            //     );
                            //     if (fileSize > 500) {
                            //       return "file size must be lower than 500kb";
                            //     }
                            //   }
                            // },
                          })}
                          accept=".mp4"
                        />
                      </Form.Group>
                      {errors.video && (
                        <span className="text-danger">
                          {errors.video.message}
                        </span>
                      )}
                    </Row>
                  </div>
                )}
                {section?.content_section?.id === 2 && (
                  <div className="main-form-section mt-3">
                    <Row className="justify-content-start">
                      <Form.Label className="text-left">Image</Form.Label>

                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image,
                          })}
                          type="file"
                          {...register("image", {
                            // validate: async (value) => {
                            //   if (typeof value !== "string") {
                            //     const fileTypes = ["jpg", "png", "jpeg"];
                            //     const fileType = value[0].name?.split(".")[1];
                            //     if (!fileTypes.includes(fileType)) {
                            //       return `please upload a valid file format. (${fileTypes})`;
                            //     }
                            //     const sizes = await getDimension(value[0]);
                            //     if (
                            //       sizes.width !== 420 &&
                            //       sizes.height !== 520
                            //     ) {
                            //       return "Image width and height must be 420 px and 520 px";
                            //     }
                            //     const fileSize = Math.round(
                            //       value[0].size / 1024
                            //     );
                            //     if (fileSize > 500) {
                            //       return "file size must be lower than 500kb";
                            //     }
                            //   }
                            // },
                          })}
                          accept=".jpg, .jpeg, .png"
                        />
                      </Form.Group>
                      {errors.image && (
                        <span className="text-danger">
                          {errors.image.message}
                        </span>
                      )}
                    </Row>
                  </div>
                )}
                {section?.content_section?.id === 5 && (
                  <div className="main-form-section mt-3">
                    <Row className="justify-content-start">
                      <Form.Label className="text-left">Logo</Form.Label>

                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.rating_logo,
                          })}
                          type="file"
                          {...register("rating_logo", {
                            // validate: async (value) => {
                            //   if (typeof value !== "string") {
                            //     const fileTypes = ["jpg", "png", "jpeg"];
                            //     const fileType = value[0].name?.split(".")[1];
                            //     if (!fileTypes.includes(fileType)) {
                            //       return `please upload a valid file format. (${fileTypes})`;
                            //     }
                            //     const sizes = await getDimension(value[0]);
                            //     if (
                            //       sizes.width !== 420 &&
                            //       sizes.height !== 520
                            //     ) {
                            //       return "Logo width and height must be 420 px and 520 px";
                            //     }
                            //     const fileSize = Math.round(
                            //       value[0].size / 1024
                            //     );
                            //     if (fileSize > 500) {
                            //       return "file size must be lower than 500kb";
                            //     }
                            //   }
                            // },
                          })}
                          accept=".jpg, .jpeg, .png"
                        />
                      </Form.Group>
                      {errors.rating_logo && (
                        <span className="text-danger">
                          {errors.rating_logo.message}
                        </span>
                      )}
                    </Row>
                  </div>
                )}
              </Col>

              <Col lg={6}>
                {section?.content_section?.id === 1 && (
                  <div className="main-form-section mt-3">
                    <Row className="justify-content-center">
                      <Form.Label column sm={4} className="text-center">
                        Video Preview
                      </Form.Label>
                      <Col sm={8}>
                        {typeof getValues("video") == "string" ? (
                          <div className="video-preview-container">
                            <video
                              src={IMG_URL + getValues("video")}
                              alt="Preview"
                              className="video-preview"
                              style={{ width: "200px", height: "200px" }}
                              controls
                            />
                          </div>
                        ) : (
                          videoFile &&
                          videoFile?.length > 0 && (
                            <div className="video-preview-container">
                              <video
                                src={URL?.createObjectURL(videoFile[0])}
                                alt="Preview"
                                className="video-preview"
                                style={{ width: "200px", height: "200px" }}
                                controls
                              />
                            </div>
                          )
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
                {section?.content_section?.id === 2 && (
                  <div className="main-form-section mt-3">
                    <Row className="justify-content-center">
                      <Form.Label column sm={4} className="text-center">
                        Image Preview
                      </Form.Label>
                      <Col sm={8}>
                        {typeof getValues("image") == "string" ? (
                          <div className="image-preview-container">
                            <img
                              src={IMG_URL + getValues("image")}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: "150px", height: "130px" }}
                            />
                          </div>
                        ) : (
                          imageFile &&
                          imageFile?.length > 0 && (
                            <div className="image-preview-container">
                              <img
                                // src={URL.createObjectURL(getValues("image")[0])}
                                src={URL?.createObjectURL(imageFile[0])}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          )
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
                {section?.content_section?.id === 5 && (
                  <div className="main-form-section mt-3">
                    <Row className="justify-content-center">
                      <Form.Label column sm={4} className="text-center">
                        Logo Preview
                      </Form.Label>
                      <Col sm={8}>
                        {typeof getValues("rating_logo") == "string" ? (
                          <div className="logo-preview-container">
                            <img
                              src={IMG_URL + getValues("rating_logo")}
                              alt="Preview"
                              className="logo-preview"
                              style={{ width: "150px", height: "130px" }}
                            />
                          </div>
                        ) : (
                          logoFile &&
                          logoFile?.length > 0 && (
                            <div className="logo-preview-container">
                              <img
                                // src={URL.createObjectURL(getValues("logo")[0])}
                                src={URL?.createObjectURL(logoFile[0])}
                                alt="Preview"
                                className="logo-preview"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          )
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>

              <Col lg={12}>
                {section?.content_section?.id === 4 && (
                  <div className="main-form-section mt-3">
                    <Row className="justify-content-start">
                      {" "}
                      {/* Align to left */}
                      {/* Description */}
                      <Col sm={12}>
                        <Form.Label className="text-center">
                          Description
                        </Form.Label>
                        <Form.Group>
                          <Controller
                            name="description" // Provide the field name
                            control={control} // Pass the control object from useForm()
                            rules={{ required: "Description is required" }} // Validation rules
                            render={({ field }) => (
                              <JoditEditor
                                value={field.value}
                                onChange={(newContent) =>
                                  field.onChange(newContent)
                                }
                                onBlur={field.onBlur}
                                className={classNames("", {
                                  "is-invalid": !!errors.description,
                                })}
                                placeholder="Description"
                              />
                            )}
                          />
                        </Form.Group>
                        {errors.description && (
                          <span className="text-danger">
                            {errors.description.message}
                          </span>
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
