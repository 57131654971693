import React from "react";
import "./advertisement_add_task_modal.css";
import { useState, useContext, useEffect } from "react";
import "../selldash-commonmodal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
import { Context } from "../../../../../../utils/context";
import useRazorpay from "react-razorpay";
import SuccessModal from "../../../../../CommonForAll/SuccessModal/SuccessModal";
import {
  getAllInfluencerApi,
  getInfluencerSocialLinksApi,
} from "../../../../../../utils/apis/affiliate/affiliate";
import {
  affiliateProductGet,
  influencerProductGet,
} from "../../../../../../utils/apis/affiliate/InHouseTask/Task/task";
import {
  AllState,
  City,
  Country,
  Pincode,
  State,
  getLanguageApi,
} from "../../../../../../utils/apis/master/Master";
import ErrorModal from "../../../../../CommonForAll/ErrorModal/ErrorModal";
import { getSellerWalletApi } from "../../../../../../utils/apis/seller/wallet/wallet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper/modules";

const Advertisement_add_task_modal = (props) => {
  const {
    getData,
    postData,
    IMG_URL,
    Select2Data,
    Id,
    Select3Data,
    userdata,
    OUTER_URL,
    sellerDomain,
  } = useContext(Context);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincodes, setPincode] = useState([]);
  const [product, setProducts] = useState([]);
  const [influencer, setInfluencer] = useState([]);
  const [allInfluencerData, setAllInfluencerData] = useState([]);
  const [platForm, setPlatForm] = useState([]);
  const [language, setLanguage] = useState([]);
  const [allLanguage, setAllLanguage] = useState([]);
  const [startDate, setStartDate] = useState();
  const [errorModal, setErrorModal] = useState(false);
  const [errorAmount, setErrorAmount] = useState("");
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [visitRequest, setVisitRequest] = useState(false);
  const [productData, setProductsData] = useState("");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (d) => {
    let payAmount = Number(d.insentive);
    if (visitRequest) {
      payAmount += Number(Math.round(d.influencer_id?.data?.visit_cost/getValues("influencer_currency")?.currency?.exchange_rate));
    }
    if (payAmount > sellerWallet?.wallet?.amount) {
      setErrorAmount(payAmount);
      setErrorModal(true);
    } else {
      finalFunction(d);
    }
  };
  const finalFunction = async (d) => {
    const data = {
      domain_id: sellerDomain?.value || "",
      insentive: d.insentive || "",
      product_id: d.productId?.value || "",
      product_type: d.product_type?.value || "",
      product_available: d.review_status?.value || "",
      validity: d.validity || "",
      highlight_point: d.highlight_point || "",
      influencer_id: d.influencer_id?.value || "",
      expected_view: d.expected_view || "",
      language_id: d.language_id?.value || "",
      country_id: d.country_id?.value || "",
      open_currency_id: d.influencer_id?.data?.open_currency_id || "",
      visit_currency_id: d.influencer_id?.data?.visit_currency_id || "",
      instruction: d.instruction || "",
      task_type: "influencer", // Corrected spelling
      website_url: sellerDomain?.url + "/" + (d.productId?.value || ""),
      platform_id: [], // Initialize as an array
    };

    // Collect platform IDs
    d.platFormId?.forEach((id) => data.platform_id.push(id.value));

    // Conditional logic for visit request
    data.visit_request = visitRequest;
    if (visitRequest) {
      data.visit_cost = Math.round(d.influencer_id?.data?.visit_cost/d.influencer_currency?.currency?.exchange_rate) || "";
      data.note = d.note || "";
      data.state_id = d.state_id?.value || "";
      data.city_id = d.city_id?.value || "";
      data.pincode_id = d.pincode_id?.value || "";
      data.date = d.date || "";
      data.address = d.address || "";
    }

    const res = await postData("/seller/seller-task/task", data);
    getMasters();

    if (res?.success) {
      props.onHide();
      reset();
      setProductsData("");
      setSuccessModalShow(true);
      props.getAdvertisement();
      setTimeout(() => {
        setSuccessModalShow(false);
      }, 2000);
    }
  };

  const SelectInfluenceData = async (data, name, other = false) => {
    const result = data.map((data) => ({
      value: data?.id,
      label: `${data?.f_name} ${data?.l_name}`,
      name: name,
      data: data?.influencer_open,
    }));

    if (other) {
      result.push({ value: "0", label: "Other", name: name });
    }
    return result;
  };

  const getInfluencerData = async (
    name = "",
    language_id = "",
    affiliate_id = ""
  ) => {
    const res = await getAllInfluencerApi(name, language_id, affiliate_id);
    if (res?.success) {
      const data = await SelectInfluenceData(res.data, "productId", false);
      setInfluencer(data);
      setAllInfluencerData(res?.data);
    }
  };
  const getMasters = async () => {
    {
      setValue("productId", "");
      const res = await influencerProductGet("BTC", sellerDomain?.value);

      if (res?.success) {
        const data = await Select3Data(res.data, "productId", false);
        await setProducts(data);
      }
    }
    {
      const res = await Country();

      if (res?.success) {
        const data = await Select2Data(res.data, "country_id", false);
        await setCountry(data);
      }
    }
    {
      const res = await AllState();

      if (res?.success) {
        const data = await Select2Data(res.data, "state_id", false);
        await setState(data);
      }
    }

    {
      const res = await getLanguageApi();

      if (res?.success) {
        const data = await Select2Data(res.data, "language_id", false);
        await setAllLanguage(data);
      }
    }
  };

  const getCity = async (id) => {
    setValue("state_id", id);
    setValue("city_id", "");
    setValue("pincode_id", "");
    const res = await City(id?.value);
    if (res?.success) {
      const data = await Select2Data(res.data, "city_id", false);
      await setCities(data);
    }
  };

  const getPincode = async (id) => {
    setValue("city_id", id);
    setValue("pincode_id", "");
    const res = await Pincode(id?.value);
    if (res?.success) {
      const data = await Select2Data(res.data, "pincode_id", false);
      await setPincode(data);
    }
  };

  const Select4Data = async (data, name, other = false) => {
    const result = data.map((data) => ({
      value: data?.id,
      label: data?.page_name + " (" + data?.social_platform?.name + ")",
      name: name,
    }));

    if (other) {
      result.push({ value: "0", label: "Other", name: name });
    }
    return result;
  };

  const SelectlanguageData = async (data, name, other = false) => {
    const result = data.map((data) => ({
      value: data?.language?.id,
      label: data?.language?.name,
      name: name,
    }));

    if (other) {
      result.push({ value: "0", label: "Other", name: name });
    }
    return result;
  };

  const handleInfluencerChange = async (value) => {
    setValue("platFormId", "");

    console.log(value,'fjsfnlsflok');
    
    setValue("influencer_id", {
      value: value?.id,
      label: `${value?.f_name} ${value?.l_name}`,
      name: "influencer_id",
      data: value?.influencer_open,
    });

    setValue('influencer_currency',value?.influencer_currency);

    setValue("insentive", Math.round(value?.influencer_open?.package_amount/value?.influencer_currency?.currency?.exchange_rate));
    const res = await getInfluencerSocialLinksApi(value.id);
    if (res?.success) {
      const data = await Select4Data(res?.data?.social, "platForm_id", false);
      setPlatForm(data);
      const languageData = await SelectlanguageData(
        res?.data?.language,
        "language_id",
        false
      );
      setLanguage(languageData);
    }
    // if (res?.success) {
    //     console.log(res?.data);
    //     setProductsData(res?.data);
    // }
  };

  const handlePincodeChange = async (product_id) => {
    const res = await getData(
      `/seller/seller-task/task/products/${product_id.value}`
    );
    if (res?.success) {
      setProductsData(res?.data);
    }
  };

  const handleDeadline = async (e = null) => {
    let selectedDate;

    if (e === null) {
      selectedDate = new Date(getValues("date"));
    } else {
      selectedDate = new Date(e);
    }

    const currentDate = new Date();

    // Calculate the difference in milliseconds between the two dates
    const differenceMs = selectedDate.getTime() - currentDate.getTime();

    // Convert the difference from milliseconds to days
    const daysDifference = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

    console.log(`Number of days until deadline: ${daysDifference}`);
    setValue("validity", daysDifference);
  };

  const options = [
    { value: "1", label: "Yes" },
    { value: "0", label: "NO" },
  ];

  const [sellerWallet, setSellerWallet] = useState();
  const getWalletHistory = async () => {
    const res = await getSellerWalletApi(1);

    if (res?.success) {
      setSellerWallet(res?.data);
    }
  };

  console.log(sellerWallet, "sellerWallet");

  const handleSelectAll = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "select-all")) {
      // If "Select All" is checked, select all options
      setValue("platFormId", platForm);
    } else {
      // If "Select All" is unchecked, remove it from the selected values
      setValue(
        "platFormId",
        selectedOptions.filter((option) => option.value !== "select-all")
      );
    }
  };

  // Add the "Select All" option dynamically
  const selectAllOption = {
    label: "Select All",
    value: "select-all",
  };

  const optionsWithSelectAll = [selectAllOption, ...platForm];

  useEffect(() => {
    getMasters();
    getInfluencerData();
    // getPlatForm();
    getWalletHistory();
  }, []);

  return (
    <>
      <section className="">
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="selldash-commonmodal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Task
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="card-swiper-section">
              <Swiper
                pagination={true}
                spaceBetween={10}
                slidesPerView={3}
                modules={[Pagination]}
                className="card-swiper"
              >
                {allInfluencerData?.map((value, index) => (
                  <SwiperSlide>
                    <div
                      className={`card-holder ${
                        value?.terminate_status || value?.block_status
                          ? "red-mark"
                          : ""
                      } ${
                        getValues("influencer_id")?.value === value?.id
                          ? "green-mark"
                          : ""
                      }`}
                    >
                      <div className="img-text-section">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="img-holder">
                              <img
                                src={
                                  value?.image !== null
                                    ? IMG_URL + value?.image
                                    : process.env.PUBLIC_URL +
                                      "/assest/images/seller-image/dummyUser.png"
                                }
                                className="product-img"
                                alt="..."
                              />
                            </div>
                          </div>

                          <div className="col-md-8">
                            <div className="text-holder">
                              <h3 className="name-holder">
                                {value?.f_name} {value?.l_name}
                              </h3>

                              <p className="name-info-holder">
                                <span>Package </span>₹{" "}
                                {value?.influencer_open?.package_amount}
                              </p>

                              <p className="info-holder">
                                <span>Visit Cost </span>₹{" "}
                                {value?.influencer_open?.visit_cost}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tabs-holder">
                        <div className="heading-holder">
                          <h3>Languages</h3>
                        </div>

                        <Swiper
                          pagination={true}
                          spaceBetween={5}
                          slidesPerView={4}
                          className="text-tab-swiper"
                        >
                          {value?.influencer_languages?.map((language) => (
                            <SwiperSlide>
                              <div className="text-tab-holder">
                                <p>{language?.language?.name}</p>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>

                      <div className="tabs-holder">
                        <Swiper
                          pagination={true}
                          spaceBetween={5}
                          slidesPerView={3}
                          className="Social-tab-swiper"
                        >
                          {value?.a_social_details?.map((social) => {
                            // Function to convert numbers to abbreviated format (e.g., 1000 -> 1K, 1000000 -> 1M)
                            const formatNumber = (num) => {
                              if (num !== null) {
                                if (num >= 1000000) {
                                  return (num / 1000000).toFixed(1) + "M";
                                } else if (num >= 1000) {
                                  return (num / 1000).toFixed(1) + "K";
                                } else {
                                  return num.toString();
                                }
                              } else {
                                return 0;
                              }
                            };

                            return (
                              <SwiperSlide key={social?.id}>
                                <div className="Social-tab-holder">
                                  <p>
                                    <img
                                      src={
                                        IMG_URL + social?.social_platform?.image
                                      }
                                      width={20}
                                    />{" "}
                                    {formatNumber(social?.subscriber)}
                                  </p>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>

                      <div className="btn-holder">
                        {/* <Button className="view_Profile_btn">View Profile</Button> */}

                        <Button
                          className="view_Profile_btn  ms-2"
                          disabled={
                            value?.terminate_status || value?.block_status
                          }
                          onClick={() => {
                            handleInfluencerChange(value);
                          }}
                        >
                          Select Influencer
                        </Button>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <hr />
            <div className="input-fluid-section">
              <div className="row">
                <div className="col-md-4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      className={classNames("", {
                        "is-invalid": errors?.search_name,
                      })}
                      {...register("search_name", {
                        // required: "Date is required",
                      })}
                      onChange={(e) => {
                        getInfluencerData(
                          e.target.value,
                          getValues("search_language")?.value,
                          getValues("search_affiliate")
                        );
                        setValue("search_name", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Language</Form.Label>
                    <Controller
                      name="search_language"
                      {...register("search_language", {
                        // required: "Language Is Required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.search_language
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={allLanguage}
                          placeholder="--- please select ---"
                          onChange={(e) => {
                            getInfluencerData(
                              getValues("search_name"),
                              e.value,
                              getValues("search_affiliate")
                            );
                            setValue("search_language", e);
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-2">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Affiliate Id</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Affiliate Id"
                      className={classNames("", {
                        "is-invalid": errors?.search_affiliate,
                      })}
                      {...register("search_affiliate", {
                        // required: "Date is required",
                      })}
                      onChange={(e) => {
                        getInfluencerData(
                          getValues("search_name"),
                          getValues("search_language")?.value,
                          e.target.value
                        );
                        setValue("search_affiliate", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-2 mt-auto">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        setValue("search_name", "");
                        setValue("search_language", "");
                        setValue("search_affiliate", "");
                        getInfluencerData();
                      }}
                    >
                      {" "}
                      Reset
                    </button>
                  </Form.Group>
                </div>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-xxl-9 col-xl-9 col-lg-12">
                <div className="form-sec">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-6">
                        {/* <Form.Group className="mb-3">
                          <Form.Label>Choose Influencer</Form.Label>
                          <Controller
                            name="influencer_id"
                            {...register("influencer_id", {
                              required: "influencer id Is Required",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.influencer_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={influencer}
                                onChange={(options) => {
                                  handleInfluencerChange(options);
                                  setValue("influencer_id", options);
                                }}
                                placeholder="--- Select Influencer ---"
                              />
                            )}
                          />
                          {errors?.influencer_id && (
                            <span className="text text-danger">
                              {errors.influencer_id.message}
                            </span>
                          )}
                        </Form.Group> */}
                        <Form.Group className="mb-3">
                          <Form.Label>Language</Form.Label>
                          <Controller
                            name="language_id"
                            {...register("language_id", {
                              required: "Language Is Required",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.language_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={language}
                                onChange={(selectoption) => {
                                  handlePincodeChange(selectoption);
                                  setValue("language_id", selectoption);
                                }}
                                placeholder="--- please select ---"
                              />
                            )}
                          />
                          {errors?.language_id && (
                            <span className="text text-danger">
                              {errors.language_id.message}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Country</Form.Label>
                          <Controller
                            name="country_id"
                            {...register("country_id", {
                              required: "Country Is Required",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.country_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={country}
                                placeholder="--- please select ---"
                              />
                            )}
                          />
                          {errors?.country_id && (
                            <span className="text text-danger">
                              {errors.country_id.message}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Product</Form.Label>
                          <Controller
                            name="productId"
                            {...register("productId", {
                              required: "Product Is Required",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.productId
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={product}
                                onChange={(selectoption) => {
                                  handlePincodeChange(selectoption);
                                  setValue("productId", selectoption);
                                }}
                                placeholder="--- please select ---"
                              />
                            )}
                          />
                          {errors?.productId && (
                            <span className="text text-danger">
                              {errors.productId.message}
                            </span>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Social Link</Form.Label>
                          <Controller
                            name="platFormId"
                            {...register("platFormId", {
                              required: "Platform Is Required",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                isMulti
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.platFormId
                                      ? "red"
                                      : baseStyles.borderColor,
                                  }),
                                }}
                                {...field}
                                options={optionsWithSelectAll}
                                placeholder="--- please select ---"
                                onChange={(selectedOptions) =>
                                  handleSelectAll(selectedOptions)
                                }
                                value={field.value}
                              />
                            )}
                          />
                          {errors?.platFormId && (
                            <span className="text text-danger">
                              {errors.platFormId.message}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Expected View</Form.Label>
                          <Form.Control
                            type="number"
                            {...register("expected_view", {
                              // required: 'Expected view is required',
                              pattern: {
                                value: /^[0-9]*$/,
                                message: "Except Only Number",
                              },
                            })}
                            placeholder="Enter expected view"
                          />
                          {errors?.expected_view && (
                            <span className="text text-danger">
                              {errors.expected_view.message}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Product available for review</Form.Label>
                          <Controller
                            name="review_status"
                            {...register("review_status", {
                              required: "Available for review is required",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.review_status
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={options}
                                placeholder="--- Please select ---"
                              />
                            )}
                          />
                          {errors?.review_status && (
                            <span className="text text-danger">
                              {errors.review_status.message}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Visit Request</Form.Label>
                          <div className="ms-4">
                            <Form.Check
                              inline
                              label="Yes"
                              name="recive"
                              type="radio"
                              value={1}
                              onChange={(e) => {
                                setVisitRequest(true);
                                handleDeadline();
                              }}
                            />
                            <Form.Check
                              inline
                              label="No"
                              name="recive"
                              type="radio"
                              value={0}
                              defaultChecked={true}
                              onChange={(e) => setVisitRequest(false)}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Deadline
                            <span className="labelsbtext">(in Days)</span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            className={classNames("", {
                              "is-invalid": errors?.validity,
                            })}
                            {...register("validity", {
                              required: "Date is required",
                            })}
                            disabled={visitRequest}
                            placeholder="Enter Deadline"
                            onChange={(e) => {
                              if (visitRequest) {
                              } else {
                              }
                            }}
                          />
                          {errors?.validity && (
                            <span className="text text-danger">
                              {errors.validity.message}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Task Amount</Form.Label>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.insentive,
                            })}
                            type="number"
                            {...register("insentive", {
                              required: "Task Amount Is Required",
                            })}
                            placeholder="Enter Amount"
                          />
                          {errors?.insentive && (
                            <span className="text text-danger">
                              {errors.insentive.message}
                            </span>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-12 col-sm-12">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Influencer Name:{" "}
                                  <b>{getValues("influencer_id")?.label}</b>
                                  <span className="labelsbtext"></span>
                                </Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-4 col-sm-6">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Influencer package
                                  <span className="labelsbtext"></span>
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>
                                  ₹ {
                                    Math.round(getValues("influencer_id")?.data
                                      ?.package_amount/getValues("influencer_currency")?.currency?.exchange_rate)
                                  }
                                </Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          {getValues("influencer_id")?.data
                            ?.place_visit_status == 1 && (
                            <div className="col-lg-4 col-sm-6">
                              <Form.Group className="mb-3">
                                <div>
                                  <Form.Label>
                                    Recieve Place Cost{" "}
                                    <span className="labelsbtext"></span>
                                  </Form.Label>
                                </div>
                                <div>
                                  <Form.Label>
                                    ₹ { 
                                      Math.round(getValues("influencer_id")?.data?.visit_cost/getValues("influencer_currency")?.currency?.exchange_rate)
                                        
                                    }
                                  </Form.Label>
                                </div>
                              </Form.Group>
                            </div>
                          )}

                          <hr />
                          <div className="col-lg-4 col-sm-6">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Brand Name{" "}
                                  
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>
                                  {productData.s_brand?.name}
                                </Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-4 col-sm-6">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Type{" "}
                                  
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>{productData.added_by}</Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-4 col-sm-6">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Industries{" "}
                                  
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>
                                  {productData.s_category?.name}
                                </Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-4 col-sm-6">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Product Review
                                  
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>4.3</Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-4 col-sm-6">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Cost
                                  
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>500</Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-4 col-sm-6">
                            <Form.Group className="mb-3">
                              <div>
                                <Form.Label>
                                  Discount
                                  
                                </Form.Label>
                              </div>
                              <div>
                                <Form.Label>5%</Form.Label>
                              </div>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xxl-4 col-xl-4 col-lg-12  col-sm-6">
                            <Form.Group className="mb-1">
                              <div>
                                <Form.Label>
                                  Image{" "}
                                  
                                </Form.Label>
                              </div>
                              <div className="">
                                <img
                                  className="bjvbhbvh-img"
                                  src={IMG_URL + productData.s_brand?.image}
                                />
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        {visitRequest && (
                          <>
                            <hr />
                            <div className="row">
                              <div className="col-lg-12  col-sm-12">
                                <Form.Group className="mb-1">
                                  <div>
                                    <Form.Label>
                                      Event Address{" "}
                                      <span className="labelsbtext">
                                        (Fully Detail Address with pincode)
                                      </span>
                                    </Form.Label>
                                  </div>
                                  <div className="">
                                    <Form.Control
                                      type="text"
                                      className={classNames("", {
                                        "is-invalid": errors?.address,
                                      })}
                                      {...register("address", {
                                        required: "Address is required",
                                      })}
                                      placeholder="Enter Address"
                                    />
                                    {errors?.address && (
                                      <span className="text text-danger">
                                        {errors.address.message}
                                      </span>
                                    )}
                                  </div>
                                </Form.Group>
                              </div>
                              <div className="col-lg-6  col-sm-6">
                                <Form.Group className="mb-1">
                                  <div>
                                    <Form.Label>Event State </Form.Label>
                                  </div>
                                  <div className="">
                                    <Controller
                                      name="state_id"
                                      {...register("state_id", {
                                        required: "State Is Required",
                                      })}
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          styles={{
                                            control: (baseStyles) => ({
                                              ...baseStyles,
                                              borderColor: errors.state_id
                                                ? "red"
                                                : baseStyles,
                                            }),
                                          }}
                                          {...field}
                                          options={state}
                                          placeholder="--- please select ---"
                                          onChange={(e) => getCity(e)}
                                        />
                                      )}
                                    />
                                    {errors?.state_id && (
                                      <span className="text text-danger">
                                        {errors.state_id.message}
                                      </span>
                                    )}
                                  </div>
                                </Form.Group>
                              </div>
                              <div className="col-lg-6  col-sm-6">
                                <Form.Group className="mb-1">
                                  <div>
                                    <Form.Label>Event City </Form.Label>
                                  </div>
                                  <div className="">
                                    <Controller
                                      name="city_id"
                                      {...register("city_id", {
                                        required: "City Is Required",
                                      })}
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          styles={{
                                            control: (baseStyles) => ({
                                              ...baseStyles,
                                              borderColor: errors.city_id
                                                ? "red"
                                                : baseStyles,
                                            }),
                                          }}
                                          {...field}
                                          options={cities}
                                          onChange={(e) => getPincode(e)}
                                          placeholder="--- please select ---"
                                        />
                                      )}
                                    />
                                    {errors?.city_id && (
                                      <span className="text text-danger">
                                        {errors.city_id.message}
                                      </span>
                                    )}
                                  </div>
                                </Form.Group>
                              </div>
                              <div className="col-lg-6  col-sm-6">
                                <Form.Group className="mb-1">
                                  <div>
                                    <Form.Label>Event Pincode </Form.Label>
                                  </div>
                                  <div className="">
                                    <Controller
                                      name="pincode_id"
                                      {...register("pincode_id", {
                                        required: "City Is Required",
                                      })}
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          styles={{
                                            control: (baseStyles) => ({
                                              ...baseStyles,
                                              borderColor: errors.pincode_id
                                                ? "red"
                                                : baseStyles,
                                            }),
                                          }}
                                          {...field}
                                          options={pincodes}
                                          placeholder="--- please select ---"
                                        />
                                      )}
                                    />
                                    {errors?.pincode_id && (
                                      <span className="text text-danger">
                                        {errors.pincode_id.message}
                                      </span>
                                    )}
                                  </div>
                                </Form.Group>
                              </div>
                              <div className="col-lg-6  col-sm-6">
                                <Form.Group className="mb-1">
                                  <div>
                                    <Form.Label>Note </Form.Label>
                                  </div>
                                  <div className="">
                                    <Form.Control
                                      type="text"
                                      className={classNames("", {
                                        "is-invalid": errors?.note,
                                      })}
                                      {...register("note", {
                                        // required: "note is required"
                                      })}
                                      placeholder="Enter Note"
                                    />
                                  </div>
                                </Form.Group>
                              </div>

                              <div className="col-lg-6  col-sm-6">
                                <Form.Group className="mb-1">
                                  <div>
                                    <Form.Label>Event Date </Form.Label>
                                  </div>
                                  <div className="">
                                    <Form.Control
                                      type="date"
                                      className={classNames("", {
                                        "is-invalid": errors?.date,
                                      })}
                                      {...register("date", {
                                        required: "Date is required",
                                        validate: (value) => {
                                          const selectedDate = new Date(value);
                                          const currentDate = new Date();
                                          if (selectedDate < currentDate) {
                                            return "Please select a future date";
                                          }
                                          return true;
                                        },
                                      })}
                                      placeholder="Enter date"
                                      min={
                                        new Date(
                                          new Date().getTime() +
                                            10 * 24 * 60 * 60 * 1000
                                        )
                                          .toISOString()
                                          .split("T")[0]
                                      } // Sets the minimum date to today
                                      onChange={(e) =>
                                        handleDeadline(e.target.value)
                                      }
                                    />
                                    {errors?.date && (
                                      <span className="text text-danger">
                                        {errors.date.message}
                                      </span>
                                    )}
                                  </div>
                                </Form.Group>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="text-end">
                      <button className="btn closebtn">Close</button>
                      <button className="btn sbmitbtn">Submit</button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-12">
                <Form.Group className="mb-1">
                  <div>
                    <Form.Label>Instructions</Form.Label>
                  </div>
                </Form.Group>
                <div className="ullistt bg">
                  <Controller
                    name="instruction" // Provide the field name
                    control={control} // Pass the control object from useForm()
                    rules={{ required: "Instruction is required" }} // Validation rules
                    render={({ field, fieldState: { error } }) => (
                      <textarea
                        {...field} // Spread field props to handle value and onChange automatically
                        className={`form-control ${error ? "is-invalid" : ""}`} // Apply error class if validation fails
                        placeholder="Instruction"
                      />
                    )}
                  />
                </div>
                <Form.Group className="mb-1">
                  <div>
                    <Form.Label>Highlight Points</Form.Label>
                  </div>
                </Form.Group>
                <div className="ullistt bg">
                  <Controller
                    name="highlight" // Provide the field name
                    control={control} // Pass the control object from useForm()
                    rules={{ required: "Highlight is required" }} // Validation rules
                    render={({ field, fieldState: { error } }) => (
                      <textarea
                        {...field} // Spread field props to handle value and onChange automatically
                        className={`form-control ${error ? "is-invalid" : ""}`} // Apply error class if validation fails
                        placeholder="Highlight"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>

          {/* <Modal.Footer>
                        <Button onClick={props.onHide}>Close</Button>
                    </Modal.Footer> */}
        </Modal>
        <SuccessModal show={successModalShow} />
        <ErrorModal
          show={errorModal}
          onHide={() => setErrorModal(false)}
          text={"Insufficient Balance in Wallet! Must Have ₹ " + errorAmount}
        />
      </section>
    </>
  );
};

export default Advertisement_add_task_modal;
