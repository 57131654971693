import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../../../Components/assets/icons/PLUS.png";
// import plus from "../../../../../../Components/assets/icons/a1.png";
import colunms from "../../../../../../Components/assets/icons/LINES.png";
import search1 from "../../../../../../Components/assets/icons/search.png";
import top from "../../../../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import { Context } from "../../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../common/ModelDelete";
import ModelBulkUpload from "../../common/ModelBulkUpload";
import { AddButton, EditButton, DeletButton } from "../../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import OffcanvasCon from "../../OffcanvasCon/OffcanvasCon";
import DataTable from "datatables.net";
import parse from "html-react-parser";
import {
  Delete,
} from "../../../../../../utils/apis/seller/outer-website/masters/content-sections/content-sections";
import Select from "react-select";
import TableImageZoom from "../../common/TableImageZoom";
import {
  getSectionData,
  getThemeContentSectionApi,
} from "../../../../../../utils/apis/myWebsite/mywebsite";
import Nav from 'react-bootstrap/Nav';

library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    IMG_URL,
    sellerDomain,
    domainData,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [showoff, setShowoff] = useState(false);
  const [searchStatus, setSearchStatus] = useState(null);

  const [contentSection, setContentSection] = useState([]);
  const getThemeContentSection = async () => {
    console.log(domainData, "sellerDomain");

    const res = await getThemeContentSectionApi(domainData?.theme?.id);

    if (res?.success) {
      setContentSection(res?.data);
    }
  };

  const [sectionData, setSectionData] = useState([]);
  const handleContent = async (id) => {
    const res = await getSectionData(id, domainData?.theme?.id);

    if (res?.success) {
      setSectionData(res?.data);
    }
  };

  useEffect(() => {
    getThemeContentSection();
  }, [changeStatus, perPage, reset, show, show1]);



  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    console.log(id, "sss");

    setShowEdit(id);
  };




  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await Delete(recordToDeleteId);
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
      handleContent(sectionData?.id);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  useEffect(() => {
    const table = new DataTable("#example");
    return () => {
      table.destroy();
    };
  }, []);

  const statusOption = [
    { label: "active", value: 1 },
    { label: "Inactive", value: 0 },
  ];

  const [modalShowImgsize, setModalShowImgsize] = React.useState(false);
  const [file, setFile] = React.useState("");

  return (
    <>
      <div className="main-advancedashboard">
        {/* <Header title={"Category"} link={"/seller/outer-website/masters/blog"} /> */}
        <section className="AdvanceDashboard">
          <div className="row">
            <div className="col-lg-12">
              <section className="Tabels tab-radio tab-radio">
                <div className="">
                  {/* container */}

                  <div className="contsubsectab">

                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey={contentSection?.[0]?.id || "defaultKey"}
                    >
                      <Nav variant="pills">
                        {contentSection?.map((value, index) => (
                          <Nav.Item key={value?.id || index} onClick={() => handleContent(value?.id)}>
                            <Nav.Link eventKey={value?.id}>
                              {value?.name} ({value?.theme_content_sections.length})
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>



                      <div className="border-line mt-3"></div>

                  <div className="row mt-3 me-0 ms-0">
                    <div className="data table-responsive">
                      <Table bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {visible.col2 && (
                              <th className="tax-name">Section Name </th>
                            )}
                            {visible.col3 && (
                              <th className="tax-name">Instructions</th>
                            )}
                            {visible.col4 && (
                              <th className="tax-name">Section Type</th>
                            )}
                            {visible.col6 && <th className="active">Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {sectionData?.theme_content_sections?.map(
                            (d, index) => {
                              return (
                                <tr className="" key={index}>
                                  {visible.col1 && <td>{++index}.</td>}
                                  {visible.col2 && <td>{d?.name}</td>}
                                  {visible.col3 && (
                                    <td>{parse(d?.instruction) || ""}</td>
                                  )}
                                  {visible.col4 && <td>{sectionData?.name}</td>}

                                  {visible.col6 && (
                                    <td>
                                      <div className="d-flex">
                                        <EditButton
                                          handleShow1={handleShow1}
                                          id={d?.id}
                                        />

                                        {d?.o_w_content_section?.id && (
                                          <DeletButton
                                            showDeleteRecord={showDeleteRecord}
                                            id={d?.o_w_content_section?.id}
                                            name={d?.name}
                                          />
                                        )}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                    </Tab.Container>


                    {/* <div className="d-flex">
                      {contentSection?.map((value, index) => (

                        <div className="add me-3" onClick={() => handleContent(value?.id)}>
                          <button
                            type="button"
                            className="btn btn-add pe-3"
                          >
                            <div >
                              {value?.name} (
                              {value?.theme_content_sections.length})
                            </div>
                          </button>
                        </div>
                      ))}
                    </div> */}

                  </div>
                  


                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />

      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />

      <TableImageZoom
        show={modalShowImgsize}
        onHide={() => setModalShowImgsize(false)}
        file={file}
      />
    </>
  );
};

export default Tables;
