import React, { useState } from "react";
import "./CallSupportModal.css";

import PhoneInput from "react-phone-input-2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { supportQueryPost } from "../../../utils/apis/support";
import ErrorModalDynamic from "../ErrorModalDynamic/ErrorModal";
import SuccessModalDynamic from "../SuccessModalDynamic/SuccessModalDynamic";

import { RegxExpression } from "../../../utils/common";
import { Errors,Placeholders } from "../../../utils/errors";

const CallSupportModal = (props) => {
  const [type, setType] = useState("affiliate");
  const [error, setErrors] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (d) => {
    let data = {};

    if (type === "affiliate") {
      data = {
        type: type,
        id: d?.affiliate_id,
      };
    }
    if (type === "seller") {
      data = {
        type: type,
        id: d?.seller_id,
      };
    }
    if (type === "other") {
      data = {
        type: type,
      };
    }
    data.query = d?.query;
    data.name = d?.name;
    data.email = d?.email;
    data.contact_no = d?.contact_no;

    console.log(data);

    const res = await supportQueryPost(data);

    if (res?.success) {
      reset();
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);

        props.onHide();
      }, 3000);
    } else {
      setErrors(res?.message);
      setErrorModal(true);
      setTimeout(() => {
        setErrorModal(false);
        setErrors("");
      }, 3000);
    }
  };

  console.log(errors);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="CallSupportModal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Raise a Query
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col lg={3} md={4} sm={4}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      onClick={() => setType("affiliate")}
                    >
                      Affiliate Query
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      onClick={() => setType("seller")}
                    >
                      Seller Query
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third" onClick={() => setType("other")}>
                      Other Queries
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>

              <Col lg={9} md={8} sm={8}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group mb-3">
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter Name"
                      {...register("name", {
                        required: "Name required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.name,
                        "is-valid": getValues("name"),
                      })}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Enter Email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: RegxExpression.email,
                          message: "Invalid email address",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.email,
                        "is-valid": getValues("email"),
                      })}
                    />
                  </div>
                  <div className="form-group mb-3">
                    
                     <Controller
                            name="contact_no"
                            control={control}
                            render={({ field }) => (
                              <div style={{ position: "relative" }}>
                                <PhoneInput
                                  country="in"
                                  name="contact_no"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                  placeholder={Placeholders.phone}
                                  inputStyle={{
                                    borderColor: errors.contact_no
                                      ? "red"
                                      : getValues("contact_no")
                                      ? "green"
                                      : "",
                                  }}
                                />
                                {/* {getValues("contact_no") && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "10px",
                                      transform: "translateY(-50%)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "green",
                                        fontSize: "1.2em",
                                      }}
                                    >
                                      ✔
                                    </span>
                                  </div>
                                )} */}
                              </div>
                            )}
                            rules={{ required: Errors.phone }}
                          />
                  </div>
                  {type === "affiliate" && (
                    <div className="form-group mb-3">
                      <Form.Control
                        type="text"
                        name="affiliate_id"
                        placeholder="Enter Affiliate ID"
                        {...register("affiliate_id", {
                          required: "Affiliate ID required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.affiliate_id,
                          "is-valid": getValues("affiliate_id"),
                        })}
                      />
                    </div>
                  )}
                  {type === "seller" && (
                    <div className="form-group mb-3">
                      <Form.Control
                        type="text"
                        name="seller_id"
                        placeholder="Enter Seller ID"
                        {...register("seller_id", {
                          required: "Seller ID required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.seller_id,
                          "is-valid": getValues("seller_id"),
                        })}
                      />
                    </div>
                  )}

                  <div className="form-group mb-3">
                    <FloatingLabel
                      controlId="floatingTextarea2"
                      label="Enter your query"
                    >
                      <Form.Control
                        as="textarea"
                        type="text"
                        name="query"
                        placeholder="Leave a query here"
                        {...register("query", {
                          required: "Query required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.query,
                          "is-valid": getValues("query"),
                        })}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="form-group mb-3">
                    <div className="text-end">
                      <button className="submitbtn btn">Submit</button>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </Tab.Container>
          <ErrorModalDynamic
            show={errorModal}
            onHide={() => setErrorModal(false)}
            text={error}
            heading={"Error!"}
          />
          <SuccessModalDynamic
            show={successModal}
            onHide={() => setSuccessModal(false)}
            text={"Query Submitted Successfully"}
          />
        </Modal.Body>

        {/* <Button onClick={props.onHide}>Close</Button> */}
      </Modal>
    </>
  );
};

export default CallSupportModal;
