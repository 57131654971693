import React, { useContext, useEffect, useState } from "react";
import "./affi-events.css";
import { getEvents } from "../../../../../utils/apis/events/event";
import { Context } from "../../../../../utils/context";
import { getFaq, getGuide } from "../../../../../utils/apis/affiliate/guide";

import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
const Faq = () => {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const { IMG_URL } = useContext(Context);
  const [events, setEvents] = useState();
  const [faq, setFaq] = useState([]);
  const [productTitle, setProductTitle] = useState("");

  const getAllFaq = async () => {
    const res = await getFaq(productTitle);
    if (res?.success) {
      setFaq(res?.data);
    }
  };
  useEffect(() => {
    getAllFaq();
  }, [productTitle]);

  return (
    <>
      <section className="afffievents-sec">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
            <div className="">
              <div className="affifaqqq">
                <div className="row">
                  <div className="col-lg-6">
                    <h4 className="cardtitle">Faqs</h4>
                  </div>

                  <div className="col-lg-6">
                    <Form>
                      <Form.Group className="form-group">
                        <Form.Control
                          value={productTitle}
                          type="search"
                          placeholder="Search Faq"
                          onChange={(e) => setProductTitle(e.target.value)}
                        />
                        
                      </Form.Group>
                    </Form>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    {faq?.map((value, faqindex) => (
                      <>
                        <div className="registration-box mt-3">
                          <p>{value?.name}</p>
                        </div>
                        {value?.a_sub_faqs?.map((subValue, subIndex) => (
                          <Accordion>
                            <Accordion.Item eventKey={faqindex}>
                              <Accordion.Header>
                                {subValue?.name}
                              </Accordion.Header>
                              <Accordion.Body>
                                <p>
                                  {" "}
                                  {htmlToReactParser.parse(
                                    subValue?.description
                                  )}
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        ))}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
