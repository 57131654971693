import React, { useContext, useEffect, useState } from "react";
import "./affi-events.css";
import { getEvents } from "../../../../../utils/apis/events/event";
import { Context } from "../../../../../utils/context";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import InfluenceTask from "../InfluencerTask/Task";
import AffiliateTask from "../Task/Task";
import Task from "./Task";

const InHouseTask = () => {
  const { userdata } = useContext(Context);
  return (
    <>
      <section className="afffievents-sec">
        <div className="row">
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <div className="advertmaintabs">
              <Nav variant="pills" className="flex-row ">
              <Nav.Item>
                  <Nav.Link eventKey="1">In-House Task</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2">Affiliate</Nav.Link>
                </Nav.Item>

                {userdata?.influencer_open &&
                  userdata?.influencer_open?.status === 1 && (
                    <Nav.Item>
                      <Nav.Link eventKey="3">Influencer</Nav.Link>
                    </Nav.Item>
                  )}
              </Nav>
            </div>

            <Tab.Content>
            <Tab.Pane eventKey="1">
                <Task />
              </Tab.Pane>
              <Tab.Pane eventKey="2">
                <AffiliateTask />
              </Tab.Pane>
              <Tab.Pane eventKey="3">
                <InfluenceTask />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default InHouseTask;
