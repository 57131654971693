import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../CreateAccountSeccseccfullyModal/CreateAccountSeccseccfullyModal.css";
import Animation from "../Animation/SuccessAnimation/Animation";

function CreateAccountSeccseccfullyModal(props) {
  return (
    <>
      <section className="CreateAccountSeccseccfullyModal">
        <Modal
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="CreateAccountSeccseccfullyModal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="pt-0">
            <Modal.Title id="contained-modal-title-vcenter">
              <div className="Successful-img">
                <div className="lotty-div">
                  <Animation />
                </div>
              </div>

              <h2>Thank You !</h2>
              {/* <h4>Account created Successfully</h4> */}
              <p>
                {!props?.sellerEdit ? (

                  'Account created Successfully! will be in touch with you shortly.'
):(
                  'Account updated Successfully!'
                )}
                
              </p>
            </Modal.Title>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default CreateAccountSeccseccfullyModal;
