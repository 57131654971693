import { React, useState, useEffect, useContext } from "react";
import "./Affi_setii_page.css";
import axios from "axios";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Form from "react-bootstrap/Form";
import classNames from "classnames";
import Social_media_links from "../../common-components/modals/social-medial/Social_media_links";
import Influencer_modal from "../../common-components/modals/influencer-modal/Influencer_modal";
import { AffiliatePersonalDetailsPost } from "../../../../../utils/apis/affiliate/affiliate";

import { Context } from "../../../../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import { RegxExpression } from "../../../../../utils/common";
import Button from "react-bootstrap/Button";
import {
  SocialPost,
  getSocial,
  OpenInfluencerPost,
  getSocialUrl,
  SocialUrlPost,
  visitePlacePost,
  getInfluencerDataApi,
  InfluencerLanguagePost,
  InfluencerCategoryPost,
  InfluencerSubCategoryPost,
  InfluencerChildCategoryPost,
  getInfluencerAddress,
  InfluencerCountryPost,
  InfluencerCurrencyPost,
} from "../../../../../utils/apis/influncer/influncer";
import {
  AllCategory,
  Category,
  ChildCategory,
  Currency,
  SocialPlatform,
  SubCategory,
  getLanguageApi,
} from "../../../../../utils/apis/master/Master";
import { Errors, Placeholders } from "../../../../../utils/errors";

import {
  FirmType,
  firmAddressType,
  City,
  State,
  Country,
  Pincode,
  pincodeWiseAll,
} from "../../../../../utils/apis/master/Master";
import {
  firmDetailNamePost,
  getFirmDetailName,
  firmAddressDetailPost,
  getfirmAddressDetail,
  firmDetailsPost,
  getfirmDetails,
} from "../../../../../utils/apis/affiliate/affiliate";
const Setting_page = () => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();
  const [defaultCountryId, setDefaultCountry] = useState("");
  const [defaultPincode, setDefaultPincode] = useState("");
  const {
    getData,
    postData,
    IMG_URL,
    Select2Data,
    Id,
    Select2CurrencyData,
    isTokenValid,
  } = useContext(Context);
  const colourOptions = [
    { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
    { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
    { value: "purple", label: "Purple", color: "#5243AA" },
    { value: "red", label: "Red", color: "#FF5630", isFixed: true },
    { value: "orange", label: "Orange", color: "#FF8B00" },
    { value: "yellow", label: "Yellow", color: "#FFC400" },
    { value: "green", label: "Green", color: "#36B37E" },
    { value: "forest", label: "Forest", color: "#00875A" },
    { value: "slate", label: "Slate", color: "#253858" },
    { value: "silver", label: "Silver", color: "#666666" },
  ];

  const [modalShow, setModalShow] = useState(false);

  const address = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  // send otp
  const [contactNo, setContactNo] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const handleChange = (e) => {
    setContactNo(e.target.value);
  };

  const sendOtp = () => {
    if (contactNo) {
      // Simulate OTP sending success
      alert("OTP sent successfully!");
      setOtpSent(true); // Update state to show the OTP input div
    } else {
      alert("Please enter a valid contact number.");
    }
  };

  // Send otp mail

  const [email, setEmail] = useState("");
  const [otpemailSent, setOtpEmailSent] = useState(false);
  const [otp, setOtp] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const sendOtpEmail = () => {
    if (email) {
      // Simulate sending OTP (You can replace this with an actual API call)
      alert("OTP sent successfully to " + email);
      setOtpSent(true);
    } else {
      alert("Please enter a valid Email Id.");
    }
  };

  const verifyOtp = () => {
    // Placeholder for OTP verification logic
    if (otp) {
      alert("OTP verified successfully!");
    } else {
      alert("Please enter the OTP.");
    }
  };

  const [platformData, setPlatformData] = useState();
  const [socialLink, setSocialLink] = useState([]);
  const [languages, setLanguage] = useState([]);
  const [categories, setCategory] = useState([]);
  const [currencies, setCurrency] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesOption, setCountriesOption] = useState([]);
  const [socialPlatform, setSocialPlatform] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincodes, setPincodes] = useState([]);
  const [addId, setAddId] = useState("");

  const getSocialIn = async () => {
    try {
      const res1 = await getSocial();
      setSocialLink(res1?.data);
    } catch (error) {
      console.error(error);
    }
    {
      const res = await getLanguageApi();
      if (res?.success) {
        const data = await Select2Data(res?.data, "language_id", false);
        setLanguage(data);
      }
    }
    {
      const res = await AllCategory();
      if (res?.success) {
        const data = await Select2Data(res?.data, "category_id", false);
        setCategory(data);
      }
    }

    {
      const res = await Currency();
      if (res?.success) {
        const data = await Select2CurrencyData(res?.data, "currency_id", false);
        setCurrency(data);
      }
    }
    {
      const res = await SocialPlatform();
      if (res?.success) {
        const data = await Select2Data(res?.data, "social_id", false);
        setSocialPlatform(data);
      }
    }
    {
      const res = await Country();
      if (res?.success) {
        const data = await Select2Data(res?.data, "country_id", false);
        setCountriesOption(data);
      }
    }
  };

  const [subCategories, setSubCategory] = useState([]);
  const getSubCategory = async (id) => {
    const res = await SubCategory(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, "sub_category_id", false);
      setSubCategory((prevState) => [...prevState, ...data]);
    }
  };

  const [childCategories, setChildCategory] = useState([]);
  const getChildCategory = async (id) => {
    const res = await ChildCategory(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, "child_category_id", false);
      setChildCategory((prevState) => [...prevState, ...data]);
    }
  };

  const [influencerData, setInfluencerData] = useState();
  const [openStatus, setOpenStatus] = useState(0);
  const [influemodalShow, influesetModalShow] = useState(false);
  const getInfluencerData = async () => {
    await setSubCategory([]);
    await setChildCategory([]);
    try {
      const res1 = await getInfluencerDataApi();
      setInfluencerData(res1?.data);

      await setValue(
        "package_amount",
        res1?.data?.influencer_open?.package_amount
      );
      await setValue("visit_cost", res1?.data?.influencer_open?.visit_cost);

      await setValue("currency_id", {
        value: res1?.data?.influencer_currency?.currency?.id,
        name: "currency_id",
        label:
          res1?.data?.influencer_currency?.currency?.name +
          " " +
          res1?.data?.influencer_currency?.currency?.symbol,
      });
      await setValue("gst_no", res1?.data?.a_firm_detail?.gst_no);
      await setValue("pan_no", res1?.data?.a_firm_detail?.pan_no);

      await setValue("branch_name", res1?.data?.a_bank_detail?.branch_name);
      await setValue("micr_code", res1?.data?.a_bank_detail?.micr_code);
      await setValue("ifsc_code", res1?.data?.a_bank_detail?.ifsc_code);
      await setValue(
        "bank_account_no",
        res1?.data?.a_bank_detail?.bank_account_no
      );
      await setValue("bank_name", res1?.data?.a_bank_detail?.bank_name);

      await setValue("first_name", res1?.data?.f_name);
      await setValue("last_name", res1?.data?.l_name);
      await setValue("contact_no", res1?.data?.contact_no);
      await setValue("email", res1?.data?.email);

      await setValue(
        "open_influencer",
        res1?.data?.influencer_open?.status
          ? { value: "1", label: "Yes" }
          : { value: "0", label: "NO" }
      );
      await setValue(
        "recieve_status",
        res1?.data?.influencer_open?.place_visit_status
          ? { value: "1", label: "Yes" }
          : { value: "0", label: "NO" }
      );

      await setReciveStatus(
        res1?.data?.influencer_open?.place_visit_status == 1
      );
      await setRecive(res1?.data?.influencer_open?.place_visit_status);
      await setOpenStatus(res1?.data?.influencer_open?.status);
      await influesetModalShow(res1?.data?.influencer_open?.status == 1);
      await setDiscount(res1?.data?.influencer_open?.discount);

      setValue(
        "influencer_categories",
        res1?.data?.influencer_categories?.map((value) => {
          getSubCategory(value?.s_category?.id);
          return {
            value: value?.s_category?.id,
            name: "category_id",
            label: value?.s_category?.name,
          };
        })
      );

      setValue(
        "influencer_subcategories",
        res1?.data?.influencer_subcategories?.map((value) => {
          getChildCategory(value?.s_sub_category?.id);
          return {
            value: value?.s_sub_category?.id,
            name: "sub_category_id",
            label: value?.s_sub_category?.name,
          };
        })
      );
      setValue(
        "influencer_childcategories",
        res1?.data?.influencer_childcategories?.map((value) => ({
          value: value?.s_child_category?.id,
          name: "child_category_id",
          label: value?.s_child_category?.name,
        }))
      );

      let selectLanguage = [];
      res1?.data?.influencer_languages?.map((value) => {
        selectLanguage.push({
          value: value?.language?.id,
          label: value?.language?.name,
        });
      });

      await setValue("influencerLanguages", selectLanguage);

      let selectCountry = [];
      res1?.data?.influencer_countries?.map((value) => {
        selectCountry.push({
          value: value?.country?.id,
          label: value?.country?.name,
        });
      });

      await setValue("influencerCountry", selectCountry);
    } catch (error) {
      console.error(error);
    }
  };

  console.log(getValues(), "pppp");
  useEffect(() => {
    getSocialIn();
    getInfluencerData();
  }, []);

  console.log(socialLink, "socialLink");

  const [discount, setDiscount] = useState(0);
  const handleDiscountChange = (e) => {
    const value = e.target.value === "yes" ? 1 : 0;
    setDiscount(value);
  };

  const [recieveStatus, setReciveStatus] = useState(false);
  const [recieve, setRecive] = useState(0);
  const handleRecieveChange = async (e, visit_cost) => {
    await setValue(
      "recieve_status",
      e ? { value: "1", label: "Yes" } : { value: "0", label: "NO" }
    );

    const data = {
      place_visit_status: e,
      visit_cost: visit_cost,
    };
    if (e == 1) {
      const res = await visitePlacePost(data);
      if (res?.success) {
        setRecive(e);
        setReciveStatus(true);
      }
    } else {
      const res = await visitePlacePost(data);
      if (res?.success) {
        setRecive(e);
        setReciveStatus(false);
      }
    }
  };

  const handleOpenInfluence = async (e, amount, discount) => {
    const data = {
      status: e,
      discount: discount,
      package_amount: amount,
    };
    if (e == 1) {
      const res = await OpenInfluencerPost(data);
      if (res?.success) {
        influesetModalShow(true);
      }
    } else {
      const res = await OpenInfluencerPost(data);
      if (res?.success) {
        influesetModalShow(false);
      }
    }
    await isTokenValid();
  };

  const handleLanguageChange = async (e) => {
    setValue("influencerLanguages", e);
    const res = await InfluencerLanguagePost({ language_id: e });
  };

  const handleContryChange = async (e) => {
    setValue("influencerCountry", e);
    const res = await InfluencerCountryPost({ country_id: e });
  };
  const handleCurrencyChange = async (e) => {
    setValue("currency_id", e);
    const res = await InfluencerCurrencyPost({ currency_id: e });
  };
  const handleInfluencerCategory = async (e) => {
    setValue("influencer_categories", e);

    const res = await InfluencerCategoryPost({ category_id: e });
    setValue("influencer_subcategories", []);
    setSubCategory([]);
    e?.map((value) => {
      getSubCategory(value?.value);
    });
  };

  const handleInfluencerSubCategory = async (e) => {
    setValue("influencer_subcategories", e);
    const res = await InfluencerSubCategoryPost({ sub_category_id: e });
    setValue("influencer_childcategories", []);
    setChildCategory([]);
    e?.map((value) => {
      getChildCategory(value?.value);
    });
  };

  const handleInfluencerChildCategory = async (e) => {
    setValue("influencer_childcategories", e);
    const res = await InfluencerChildCategoryPost({ child_category_id: e });
  };

  const handlePincodeChange = async (e) => {
    const res = await Pincode(e);
    if (res?.success) {
      const data = await Select2Data(res.data, "pincode_id", false);
      setPincodes(data);
    }
  };

  const handlePincodeSelect = async (id) => {
    const res = await pincodeWiseAll(id);
    if (res?.success) {
      setCities(res.data.city?.label);
      setStates(res.data.state?.label);
      setCountries(res.data.country?.label);
      await setValue("city_id", res.data.city_id);
      await setValue("state_id", res.data.state_id);
      await setValue("country_id", res.data.country_id);
      await setValue("pincode_id", {
        value: res.data?.id,
        label: res.data?.name,
      });
    }
  };

  const getfirmAddressDetailIn = async () => {
    const res = await getInfluencerAddress();
    console.log(res, "res res");
    if (res?.success) {
      const resetData = {
        add_line_one: res.data?.add_line_one,
        add_line_two: res.data?.add_line_two,
        address_name: res.data?.address_name,
        pincode_id: res.data?.pincode_id,
        city_id: res.data?.city_id,
        state_id: res.data?.state_id,
        country_id: res.data?.country_id,
        c_personal_details_id: res.data?.c_personal_details_id,
      };

      console.log(resetData);

      handlePincodeSelect(res.data.pincode_id.value);

      setAddId(res.data.id);
      //   setFirmType(res.data.firm_type_id);
      setDefaultPincode(resetData.pincode_id);
      await setValue("add_line_one", resetData.add_line_one);
      await setValue("add_line_two", resetData.add_line_two);
      await setValue("address_name", resetData.address_name);
      await setValue("pincode_id", resetData.pincode_id);
      await setValue("c_personal_details_id", resetData.c_personal_details_id);
    }
  };

  const options = [
    { value: "1", label: "Yes" },
    { value: "0", label: "NO" },
  ];

  const submitProfile = async () => {
    const data = {
      address_name: getValues("address_name"),
      add_line_one: getValues("add_line_one"),
      add_line_two: getValues("add_line_two"),
      pincode_id: getValues("pincode_id"),
      city_id: getValues("city_id"),
      state_id: getValues("state_id"),
      country_id: getValues("country_id"),
      contact_no: getValues("contact_no"),
      email: getValues("email"),
    };
  };

  useEffect(() => {
    // getMasters();
    // getFirmDetailNames();
    // getFirmTypes();
    getfirmAddressDetailIn();
  }, []);
  return (
    <>
      <section className="affi-setting-seccc">
        <h5 className="aff-title">
          <span className="orange-border">Preference</span> ads category
        </h5>

        <div className="row my-3">
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
            <Form.Label>Category</Form.Label>

            <Controller
              name="influencer_categories" // name of the field
              {...register("influencer_categories", {
                // required: "Select Category",
              })}
              control={control}
              render={({ field }) => (
                <Select
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: errors.influencer_categories
                        ? "red"
                        : baseStyles,
                    }),
                  }}
                  {...field}
                  isMulti
                  options={categories}
                  onChange={(e) => handleInfluencerCategory(e)}
                />
              )}
            />
          </div>
          <div className="col-xl-3  col-lg-4 col-md-6 col-sm-12 mb-2">
            <Form.Label>Sub Category</Form.Label>
            <Controller
              name="influencer_subcategories" // name of the field
              {...register("influencer_subcategories", {
                // required: "Select Category",
              })}
              control={control}
              render={({ field }) => (
                <Select
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: errors.influencer_subcategories
                        ? "red"
                        : baseStyles,
                    }),
                  }}
                  {...field}
                  isMulti
                  options={subCategories}
                  onChange={(e) => handleInfluencerSubCategory(e)}
                />
              )}
            />
          </div>
          <div className="col-xl-3  col-lg-4 col-md-6 col-sm-12 mb-2">
            <Form.Label>Child Category</Form.Label>
            <Controller
              name="influencer_childcategories" // name of the field
              {...register("influencer_childcategories", {
                // required: "Select Category",
              })}
              control={control}
              render={({ field }) => (
                <Select
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: errors.influencer_childcategories
                        ? "red"
                        : baseStyles,
                    }),
                  }}
                  {...field}
                  isMulti
                  options={childCategories}
                  onChange={(e) => handleInfluencerChildCategory(e)}
                />
              )}
            />
          </div>
        </div>
        {/* <h5 className='aff-title'><span className='orange-border'>Industry</span></h5>
                <div className='row my-3'>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
                        <Form.Label>Sector</Form.Label>
                        <Select
                            closeMenuOnSelect={false}
                            // components={animatedComponents}
                            defaultValue={[colourOptions[4], colourOptions[5]]}
                            isMulti
                            options={colourOptions}
                        />
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
                        <Form.Label>Type</Form.Label>
                        <Select
                            closeMenuOnSelect={false}
                            // components={animatedComponents}
                            defaultValue={[colourOptions[4], colourOptions[5]]}
                            isMulti
                            options={colourOptions}
                        />
                    </div>
                </div> */}
        <h5 className="aff-title">
          <span className="orange-border">Social</span> media link
        </h5>
        <div className="row my-3">
          <div className="col-md-12 mb-2">
            <div className="d-flex flex-wrap socialmeddlinkflex justify-content-start">
              {socialLink?.map((val, index) => (
                <div
                  className="data p-2 m-2"
                  key={index}
                  style={{ flexBasis: "250px", minWidth: "250px" }}
                >
                  <div className="d-flex justify-content-between mb-2">
                    <p
                      className="mb-0 cursor-pointer"
                      onClick={() => {
                        setPlatformData(val);
                        setModalShow(true);
                      }}
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen"
                        className="x-markk"
                      />
                    </p>
                    <p className="mb-0 cursor-pointer">
                      <FontAwesomeIcon
                        icon="fa-solid fa-xmark"
                        className="x-markk2"
                      />
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>Platform:</b>{" "}
                      <img
                        width={20}
                        src={IMG_URL + val?.social_platform_id?.image}
                        alt="Platform Icon"
                      />{" "}
                      {val?.social_platform_id?.label}
                    </p>
                    <p>
                      <b>Page Link:</b>{" "}
                      <a
                        href={val?.page_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {val?.page_link}
                      </a>
                    </p>
                    <p>
                      <b>Page Name:</b> {val?.page_name}
                    </p>
                    <p>
                      <b>Subscribers / Followers:</b> {val?.subscriber}
                    </p>
                    <p>
                      <b>Channel ID:</b> {val?.channel_id}
                    </p>
                  </div>
                </div>
              ))}

              {/* Add new link button */}
              <div
                className="data-plus p-2 m-2"
                style={{
                  flexBasis: "50px",
                  minWidth: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => setModalShow(true)}
              >
                <FontAwesomeIcon icon="fa-solid fa-plus" className="addico" />
              </div>
            </div>
          </div>
        </div>

        <h5 className="aff-title">
          <span className="orange-border">Contact</span> details
        </h5>
        <div className="formmbgd mb-4">
          <Form>
            <div className="row my-3">
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
                <Form.Group className="mb-3 " controlId="">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_Name"
                    placeholder="Enter First Name"
                    {...register("f_name", {
                      required: "First name required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.f_name,
                      "is-valid": getValues("f_name"),
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.name.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
                <Form.Group className="mb-3 " controlId="">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_Name"
                    placeholder="Enter Last Name"
                    {...register("f_name", {
                      required: "Last name required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.f_name,
                      "is-valid": getValues("f_name"),
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.name.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
                <Form.Group className="mb-3" controlId="contactNo">
                  <Form.Label>Contact No.</Form.Label>
                  <div className="otpppdiv">
                    <Form.Control
                      type="text"
                      name="contact_no"
                      placeholder="contact_no"
                      className={classNames("", {
                        "is-invalid": errors?.contact_no,
                      })}
                      {...register("contact_no", {
                        required: "contact_no is required",
                      })}
                    />
                    {errors.contact_no && (
                      <span className="text-danger">
                        {errors.contact_no.message}
                      </span>
                    )}
                    {/* <Form.Control
                                            type="text"
                                            placeholder="Enter your contact no."
                                            value={contactNo}
                                            onChange={handleChange}
                                        /> */}
                    {/* <button className='ottbtn btn' onClick={sendOtp}>Send OTP</button> */}
                  </div>
                </Form.Group>

                {/* {otpSent && (
                                    <div>
                                        <Form.Group className="mb-3" controlId="otp">
                                            <Form.Control type="text" placeholder="Enter OTP" />
                                        </Form.Group>
                                        <button className='btn submittttbtnnn'>Enter OTP</button>
                                    </div>
                                )} */}
              </div>

              {/* <div className='col-md-3 mb-2'>
                                <Form.Group className="mb-3 " controlId="">
                                    <Form.Label>Email</Form.Label>
                                    <div className='otpppdiv'>
                                        <Form.Control type="text" placeholder="Enter your email" />
                                        <button className='ottbtn btn'>Send Otp</button>
                                    </div>
                                 
                                </Form.Group>
                            </div> */}

              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <div className="otpppdiv">
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="email"
                      className={classNames("", {
                        "is-invalid": errors?.email,
                      })}
                      {...register("email", {
                        required: "email is required",
                      })}
                    />
                    {errors.email && (
                      <span className="text-danger">
                        {errors.email.message}
                      </span>
                    )}
                    {/* <Form.Control
                                            type="text"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={handleEmailChange}
                                        /> */}
                    {/* <button className='ottbtn btn' onClick={sendOtpEmail}>Send OTP</button> */}
                  </div>
                </Form.Group>

                {/* {otpSent && (
                                    <div>
                                        <Form.Group className="mb-3" controlId="otp">
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter OTP"
                                                value={otp}
                                                onChange={handleOtpChange}
                                            />
                                        </Form.Group>
                                        <button className='btn submittttbtnnn' onClick={verifyOtp}>Submit OTP</button>
                                    </div>
                                )} */}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
                <Form.Group className="mb-3 " controlId="">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address_name"
                    placeholder={Placeholders.address_name}
                    {...register("address_name", {
                      required: Errors.address_name,
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.address_name,
                    })}
                  />
                </Form.Group>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
                <Form.Group className="mb-3 " controlId="">
                  <Form.Label>Flat</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.add_line_one,
                    })}
                    type="text"
                    name="add_line_one"
                    placeholder={Placeholders.address_line_one}
                    {...register("add_line_one", {
                      required: Errors.address_line_one,
                    })}
                  />
                </Form.Group>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
                <Form.Group className="mb-3 " controlId="">
                  <Form.Label>Town</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.add_line_two,
                    })}
                    type="text"
                    name="add_line_two"
                    placeholder={Placeholders.address_line_two}
                    {...register("add_line_two", {
                      required: Errors.address_line_two,
                    })}
                  />
                </Form.Group>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
                <Form.Group className="mb-3 " controlId="">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.city,
                    })}
                    type="text"
                    name="city_id"
                    readOnly
                    value={cities}
                    placeholder={Placeholders.city}
                  />
                </Form.Group>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
                <Form.Group className="mb-3 " controlId="">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.state,
                    })}
                    type="text"
                    name="state_id"
                    value={states}
                    placeholder={Placeholders.state}
                    readOnly
                  />
                </Form.Group>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
                <Form.Group className="mb-3 " controlId="">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.country,
                    })}
                    type="text"
                    name="country_id"
                    readOnly
                    value={countries}
                    placeholder={Placeholders.country}
                  />
                </Form.Group>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
                <Form.Group className="mb-3 " controlId="">
                  <Form.Label>Pin Code</Form.Label>
                  <Controller
                    name="pincode_id"
                    {...register("pincode_id", {
                      required: Errors.pincode,
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.pincode_id ? "red" : baseStyles,
                          }),
                        }}
                        {...field}
                        options={pincodes}
                        defaultValue={{ defaultPincode }}
                        onInputChange={(selectedOption) => {
                          handlePincodeChange(selectedOption);
                        }}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value);
                          handlePincodeSelect(selectedOption.value);
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </div>

              <div className="col-md-12 mb-2">
                <button
                  className="btn submittttbtnnn"
                  type="button"
                  onClick={submitProfile}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </div>

        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
            <h5 className="aff-title">
              <span className="orange-border">Influencer</span> Language
            </h5>
            <div className="row my-3">
              <div className="col-md-12 mb-2">
                <Form.Label>Language</Form.Label>

                <Controller
                  name="influencerLanguages" // name of the field
                  {...register("influencerLanguages", {
                    // required: "Select Category",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.influencerLanguages
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      {...field}
                      isMulti
                      options={languages}
                      onChange={(e) => handleLanguageChange(e)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
            <h5 className="aff-title">
              <span className="orange-border">Target</span> audience country
            </h5>
            <div className="row my-3">
              <div className="col-md-12 mb-2">
                <Form.Label>Country</Form.Label>

                <Controller
                  name="influencerCountry" // name of the field
                  {...register("influencerCountry", {
                    // required: "Select Category",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.influencerCountry
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      {...field}
                      isMulti
                      options={countriesOption}
                      onChange={(e) => handleContryChange(e)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
            <h5 className="aff-title">
              <span className="orange-border">Currency</span>
            </h5>
            <div className="row my-3">
              <div className="col-md-12 mb-2">
                <Form.Group className="mb-3 " controlId="">
                  <Form.Label>Currency</Form.Label>
                  <Controller
                    name="currency_id" // name of the field
                    {...register("currency_id", {
                      // required: "Select Category",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.currency_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={currencies}
                        onChange={(e) => handleCurrencyChange(e)}
                      />
                    )}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
            <h5 className="aff-title">
              <span className="orange-border">Open</span> for influencer
            </h5>
            <Form className="mt-5">
              <div key="openStatus" className="mb-3">
                <Controller
                  name="open_influencer"
                  {...register("open_influencer", {
                    // required: "Available for review is required",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.open_influencer
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      {...field}
                      options={options}
                      placeholder="--- Please select ---"
                      onChange={(e) =>
                        handleOpenInfluence(
                          e.value,
                          getValues("package_amount"),
                          discount
                        )
                      }
                    />
                  )}
                />

                {/* <Form.Check
                                    inline
                                    label="Yes"
                                    name="openStatus"
                                    type="radio"
                                    value={1}
                                    defaultChecked={openStatus == 1}

                                    onClick={(e) => handleOpenInfluence(e.target.value, getValues('package_amount'), discount, getValues('open_currency_id'))}
                                />
                                <Form.Check
                                    inline
                                    label="No"
                                    name="openStatus"
                                    type="radio"
                                    value={0}
                                    defaultChecked={openStatus == 0}

                                    onClick={(e) => handleOpenInfluence(e.target.value, getValues('package_amount'), discount, getValues('open_currency_id'))}
                                /> */}
              </div>

              {influemodalShow && (
                <>
                  {/* <Form.Group className="mb-3" controlId="">
                                        <Form.Label>Currency</Form.Label>
                                        <Controller
                                            name="open_currency_id" // name of the field
                                            {...register("open_currency_id", {
                                                // required: "Select Category",
                                            })}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: errors.open_currency_id
                                                                ? "red"
                                                                : baseStyles,
                                                        }),
                                                    }}
                                                    {...field}
                                                    options={currencies}
                                                    onClick={(e) => handleOpenInfluence(1, getValues('package_amount'), discount, e)}
                                                />
                                            )}
                                        />
                                    </Form.Group> */}
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Influencer package Amount</Form.Label>
                    <Form.Control
                      type="text"
                      name="package_amount"
                      placeholder="package_amount"
                      className={classNames("", {
                        "is-invalid": errors?.package_amount,
                      })}
                      {...register("package_amount", {
                        required: "package amount is required",
                      })}
                      onChange={(e) => {
                        handleOpenInfluence(1, e.target.value, discount);
                      }}
                    />
                    {errors.package_amount && (
                      <span className="text-danger">
                        {errors.package_amount.message}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="discount">
                    <Form.Label>Discount</Form.Label>
                    <div key="inline-radio" className="mb-3">
                      <Form.Check
                        inline
                        label="Yes"
                        name="discount"
                        type="radio"
                        value={1}
                        defaultChecked={discount == 1}
                        onChange={(e) =>
                          handleOpenInfluence(
                            1,
                            getValues("package_amount"),
                            e.target.value
                          )
                        }
                      />
                      <Form.Check
                        inline
                        label="No"
                        name="discount"
                        type="radio"
                        value={0}
                        defaultChecked={discount == 0}
                        onChange={(e) =>
                          handleOpenInfluence(
                            1,
                            getValues("package_amount"),
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </Form.Group>
                </>
              )}
            </Form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h5 className="aff-title">
              <span className="orange-border">Recieve</span> place visit
            </h5>
            <div className="row my-3">
              {/* <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Add Place</Form.Label>
                                    <Form.Control type="text" placeholder="Add place" />
                                </Form.Group>
                            </div> */}

              <div className="col-xl-3 col-lg-4 col-md-6  mb-2">
                {/* <Form className='mt-5'>
                                    {['radio'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="group1"
                                                type={type}
                                                id={`inline-${type}-1`}


                                            />
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="group1"
                                                type={type}
                                                id={`inline-${type}-2`}
                                            />

                                        </div>
                                    ))}
                                </Form> */}
                <Form.Group className="mb-3" controlId="discount">
                  {/* <Form.Label>Discount</Form.Label> */}
                  <div key="inline-radio" className="mb-3">
                    <Controller
                      name="recieve_status"
                      {...register("recieve_status", {
                        // required: "Available for review is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.recieve_status
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={options}
                          placeholder="--- Please select ---"
                          onChange={(e) =>
                            handleRecieveChange(
                              e.value,
                              getValues("visit_cost")
                            )
                          }
                        />
                      )}
                    />

                    {/* <Form.Check
                                            inline
                                            label="Yes"
                                            name="recive"
                                            type="radio"
                                            value={1}
                                            defaultChecked={recieve == 1}
                                            onChange={(e) => handleRecieveChange(e.target.value, getValues('visit_cost'), getValues('visit_currency_id'))}
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="recive"
                                            type="radio"
                                            value={0}
                                            defaultChecked={recieve == 0}
                                            onChange={(e) => handleRecieveChange(e.target.value, getValues('visit_cost'), getValues('visit_currency_id'))}
                                        /> */}
                  </div>
                </Form.Group>
              </div>

              {/* <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Visit Cost</Form.Label>
                                    <Form.Control type="text" placeholder="Add place" />
                                </Form.Group>
                            </div> */}

              {recieveStatus && (
                <>
                  <Form>
                    {/* <div className='col-xl-3 col-lg-4 col-md-6  mb-2'>
                                            <Form.Group className="mb-3" controlId="">
                                                <Form.Label>Currency</Form.Label>
                                                <Controller
                                                    name="visit_currency_id" // name of the field
                                                    {...register("visit_currency_id", {
                                                        // required: "Select Category",
                                                    })}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors.visit_currency_id
                                                                        ? "red"
                                                                        : baseStyles,
                                                                }),
                                                            }}
                                                            {...field}
                                                            options={currencies}
                                                            onChange={(e) => handleRecieveChange(recieve, getValues('visit_cost'), e)}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div> */}
                    <div className="col-xl-3 col-lg-4 col-md-6  mb-2">
                      <Form.Group className="mb-3" controlId="visitCost">
                        <Form.Label>Visit Cost</Form.Label>
                        <Form.Control
                          type="text"
                          name="visit_cost"
                          placeholder="Visit Cost"
                          className={classNames("", {
                            "is-invalid": errors?.visit_cost,
                          })}
                          {...register("visit_cost", {
                            required: "Visit cost is required",
                          })}
                          onChange={(e) =>
                            handleRecieveChange(recieve, e.target.value)
                          }
                        />
                        {errors.visit_cost && (
                          <span className="text-danger">
                            {errors.visit_cost.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                    {/* <div >
                                            <Button className="btn submit-btn me-3" type="submit">
                                                Submit
                                            </Button>
                                        </div> */}
                  </Form>
                </>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <h5 className="aff-title">
              <span className="orange-border">Tax</span> & Payment Account
            </h5>
            <div className="row my-3">
              <div className="col-xl-3 col-lg-4 col-md-6  mb-2">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>GST No</Form.Label>
                  <Form.Control
                    type="text"
                    name="gst_no"
                    placeholder="Enter your GST number"
                    {...register("gst_no", {
                      required: "GST Number is required",
                      pattern: {
                        value:
                          /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[1-9A-Z]{1}$/,
                        message: "Invalid GST Number",
                      },
                    })}
                    // className={classNames("", {
                    //     "is-invalid": errors?.gst_no,
                    //     "is-valid": verifyStatus,
                    // })}
                    // onChange={() => {
                    //     setError("gst_no", "");
                    //     setVerifyStatus(false);
                    // }}
                  />
                </Form.Group>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6  mb-2">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>PAN No</Form.Label>
                  <Form.Control
                    type="text"
                    name="pan_no"
                    placeholder="Enter your PAN number"
                    {...register("pan_no", {
                      required: "PAN Number is required",
                      pattern: {
                        value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                        message: "Invalid PAN Number",
                      },
                    })}
                    // className={classNames("", {
                    //     "is-invalid": errors?.pan_number,
                    //     "is-valid": verifyPanStatus,
                    // })}
                    // onChange={() => {
                    //     setError("pan_no", "");
                    //     setVerifyPanStatus(false);
                    // }}
                  />
                </Form.Group>
              </div>
              {/* <div className='col-md-3 mb-2'>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>International No</Form.Label>
                                    <Form.Control type="number" placeholder="International No" />
                                </Form.Group>
                            </div> */}
            </div>
          </div>
          <div className="col-md-12">
            <h5 className="aff-title">
              <span className="orange-border">Bank</span> Account
            </h5>
            <div className="row my-3">
              <div className="col-xl-3 col-lg-4 col-md-6  mb-2">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.bank_name,
                    })}
                    disabled
                    type="text"
                    name="bank_name"
                    placeholder="Enter Bank Name"
                    {...register("bank_name", {
                      required: "Field is required",
                    })}
                  />
                </Form.Group>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6  mb-2">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Ifsc Code</Form.Label>
                  <Form.Control
                    // className={classNames("", {
                    //   "is-invalid": errors?.ifsc_code,
                    //   "is-valid": verifyStatus,
                    // })}
                    type="text"
                    name="ifsc_code"
                    placeholder="Enter IFSC Code."
                    {...register("ifsc_code", {
                      required: "Field is required",
                      //   pattern: {
                      //     value: RegxExpression.ifsc,
                      //     message: "Invalid IFSC code",
                      //   },
                    })}
                    // onChange={() => {
                    //   setError("ifsc_code", "");
                    //   setVerifyStatus(false);
                    // }}
                    // onKeyDown={(event) => {
                    //   if (!RegxExpression.ifsc_code.test(event.key) && event.key !== "Backspace") {
                    //     event.preventDefault();
                    //   }
                    // }}
                  />
                </Form.Group>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6  mb-2">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Bank Account No</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.bank_account_no,
                    })}
                    disabled
                    type="text"
                    name="bank_account_no"
                    placeholder="Enter bank_account_no"
                    {...register("bank_account_no", {
                      required: "Field is required",
                    })}
                  />
                </Form.Group>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6  mb-2">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>branch name</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.branch_name,
                    })}
                    disabled
                    type="text"
                    name="branch_name"
                    placeholder="Enter Bank Name"
                    {...register("branch_name", {
                      required: "Field is required",
                    })}
                  />
                </Form.Group>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6  mb-2">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>micr_code</Form.Label>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.micr_code,
                    })}
                    disabled
                    type="text"
                    name="micr_code"
                    placeholder="Enter Bank Name"
                    {...register("micr_code", {
                      required: "Field is required",
                    })}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>

        <button class="btn submittttbtnnn" type="button">
          Submit
        </button>
      </section>

      <Social_media_links
        show={modalShow}
        onHide={() => {
          setPlatformData();
          setModalShow(false);
        }}
        platformData={platformData}
        getAllSocialLink={getSocialIn}
        setPlatformData={setPlatformData}
      />

      {/* <Influencer_modal
                show={influemodalShow}
                onHide={() => influesetModalShow(false)}
            /> */}
    </>
  );
};

export default Setting_page;
