import {
    getData,
  } from "../../../api";
  
  export const getGuide = async () => {
    try {
      return await getData(`/affiliate/affiliate-guide`);
    } catch (error) {
      console.log(error);
    }
  };

  export const getFaq = async (name) => {
    try {
      return await getData(`/affiliate/affiliate-faq?search_name=${name}`);
    } catch (error) {
      console.log(error);
    }
  };