import React, { useContext, useEffect, useState } from "react";
import './Social_media_links.css'
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import Select from 'react-select';

import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// import Col from "react-bootstrap/Col";
// import Form from "react-bootstrap/Form";
// import Row from "react-bootstrap/Row";
import {
    SocialPost,
    getSocial,

    getSocialUrl,
    SocialUrlPost
} from "../../../../../../utils/apis/influncer/influncer";
import { Context } from "../../../../../../utils/context";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { SocialPlatform } from "../../../../../../utils/apis/master/Master";
const Social_media_links = (props) => {


    const { Select2Data } = useContext(Context);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,

        setError,
        clearErrors,
        formState: { errors },
        reset,
    } = useForm();
    const [socialLink, setSocialLink] = useState([]);

    const getSocialIn = async () => {
        try {
            const res1 = await SocialUrlPost();
            setSocialLink(res1?.data)
        } catch (error) {
            console.error(error);
        }
    };


    const [socialPlatform, setSocialPlatform] = useState([]);
    const getMaster = async () => {
        {
            const res = await SocialPlatform();
            if (res?.success) {
                const data = await Select2Data(res?.data, 'social_id', false);
                setSocialPlatform(data);
            }
        }
    }
    useEffect(() => {
        // getSocialIn();
        getMaster();
    }, []);



    console.log(props.platformData, 'props.platformData');

    useEffect(() => {
        if (props.platformData) {
            reset(props.platformData);
        } else {
            reset();
            setValue('id', "");
            setValue('social_platform_id', "");
            setValue('subscriber', "");
            setValue('page_name', "");
            setValue('page_link', "");
        }
    }, [props.show]);

    const onSubmit = async (d) => {
        try {
            const res1 = await SocialUrlPost(d);
            props.onHide();
            props.getAllSocialLink();
            // setSocialLink(res1?.data)
        } catch (error) {
            console.error(error);
        }
    }




    return (
        <>
            <section className=''>
                <Modal
                    className="modal-dialog-scrollable Social_media_links_mod"
                    {...props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-sec-start">
                            <Form onSubmit={handleSubmit(onSubmit)}>

                                <Form.Group>
                                    <Form.Label>Social Media</Form.Label>

                                    <Controller
                                        name="social_platform_id" // name of the field
                                        {...register("social_platform_id", {
                                            required: "Social Media is required",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.social_platform_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={socialPlatform}
                                            />
                                        )}
                                    />
                                    {errors.social_platform_id && (
                                        <span className="text-danger">
                                            {errors.social_platform_id.message}
                                        </span>
                                    )}
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Page Link</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="page_link"
                                        placeholder="Page Link"
                                        className={classNames("", {
                                            "is-invalid": errors?.page_link,
                                        })}
                                        {...register("page_link", {
                                            required: "Page Link is required",
                                        })}
                                    />
                                    {errors.page_link && (
                                        <span className="text-danger">
                                            {errors.page_link.message}
                                        </span>
                                    )}
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Page Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="page_name"
                                        placeholder="Page Name"
                                        className={classNames("", {
                                            "is-invalid": errors?.page_name,
                                        })}
                                        {...register("page_name", {
                                            required: "Page Name is required",
                                        })}
                                    />
                                    {errors.page_name && (
                                        <span className="text-danger">
                                            {errors.page_name.message}
                                        </span>
                                    )}
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Total Subscriber</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="subscriber"
                                        placeholder="Total Subscriber / Follower"
                                        className={classNames("", {
                                            "is-invalid": errors?.subscriber,
                                        })}
                                        {...register("subscriber", {
                                            required: "Total Subscriber / Follower is required",
                                        })}
                                    />
                                    {errors.subscriber && (
                                        <span className="text-danger">
                                            {errors.subscriber.message}
                                        </span>
                                    )}
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Channel ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="channel_id"
                                        placeholder="Channel ID"
                                        className={classNames("", {
                                            "is-invalid": errors?.channel_id,
                                        })}
                                        {...register("channel_id", {
                                            required: "Channel ID is required",
                                        })}
                                    />
                                    {errors.channel_id && (
                                        <span className="text-danger">
                                            {errors.channel_id.message}
                                        </span>
                                    )}
                                </Form.Group>

                                {/* <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Social media link</Form.Label>
                                    <Form.Control type="text" placeholder="Enter social media link" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Page name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter page name" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Total subscriber</Form.Label>
                                    <Form.Control type="text" placeholder="Enter total subscriber" />
                                </Form.Group> */}

                                <div className="button-holder text-center mb-5 mt-3">
                                    <Button className="btn submitt-btn me-3" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    )
}

export default Social_media_links