import React, { useState } from "react";
import Tasktable from "./Tasktable/Tasktable";
import Taskstock from "./Taskstock/Taskstock";
import "./affi-events.css";
import { getEvents } from "../../../../../utils/apis/events/event";
import { Context } from "../../../../../utils/context";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";


import ActiveTask from "../InfluencerActiveTask/Task";

const Orders = () => {


  
  const [products, setProducts] = useState({});
  return (
    <>
      <section className="influence-task-sec">
        <div className="row">
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <div className="influnce-tasktabs">
              <Nav variant="pills" className="flex-row ">
                <Nav.Item>
                  <Nav.Link eventKey="1">Tasks</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="2">Active Task</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            <Tab.Content>
              <Tab.Pane eventKey="1">
                <Taskstock  products={products} />
                <Tasktable products={products} setProducts={setProducts}/>
              </Tab.Pane>
              <Tab.Pane eventKey="2">
                  <ActiveTask />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default Orders;
