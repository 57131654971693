import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "./Wallet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import { depositAmountPost, getaffiliateWalletApi } from "../../../../../utils/apis/seller/wallet/wallet";
import Pagination from 'react-bootstrap/Pagination';
import DepositModal from "../../../../CommonForAll/mrp-modal/DepositModal";

import useRazorpay from "react-razorpay";
import SuccessModalDynamic from "../../../../CommonForAll/SuccessModalDynamic/SuccessModalDynamic";
import { depositAffiliateAmountPost, getAffiliateWalletApi, withdrawAmountPost, getExportStatement } from "../../../../../utils/apis/affiliate/wallet/wallet";
import { Context } from "../../../../../utils/context";
import ErrorModal from "../../../../CommonForAll/ErrorModalDynamic/ErrorModal";
const Wallet = () => {
  const [filterText, setFilterText] = useState("");
  const columns = [
    {
      name: "Transaction Id",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "From",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "To",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Coin",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.year,
      sortable: true,
    },
  ];

  const data = [
    {
      id: 1,
      title: "Beetlejuice",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
  ];

  const filteredData = data.filter((item) =>
    item.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [affiliateWallet, setAffiliateWallet] = useState();
  const getWalletHistory = async () => {
    const res = await getAffiliateWalletApi(currentPage);
    if (res?.success) {
      setAffiliateWallet(res);
      setCurrentPage(res?.data?.currentPage);
      setTotalPages(res?.data?.totalPages);
    }
  };

  const { dateShow, IMG_URL, isTokenValid } = useContext(Context);
  const [depositAmount, setAmount] = useState(0);
  const [amountModal, setAmountModal] = useState(false);
  const [type, setType] = useState("");
  const addWallet = (type) => {
    setType(type);
    setAmountModal(true);
  }


  const [Razorpay, openRazorpay] = useRazorpay();
  const handleRazorpay = async () => {

    const options = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: depositAmount * 100,
      currency: "INR",
      name: "Netpurti",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        depositRazor(res.razorpay_payment_id);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };


  const [successModalShow, setSuccessModalShow] = useState(false);
  const depositRazor = async (razor) => {
    const res = await depositAffiliateAmountPost(depositAmount, razor);
    if (res?.success) {
      setSuccessModalShow(true);
      isTokenValid();
      setTimeout(() => {
        setAmount(0);
        getWalletHistory();
        setSuccessModalShow(false);
      }, 2000);
    }
  }

  const [errorModal, setErrorModal] = useState({
    show: false, heading: "", text: ""
  });

  const handleWithdraw = async () => {
    const res = await withdrawAmountPost(depositAmount);
    if (res?.success) {
      setSuccessModalShow(true);
      isTokenValid();
      setTimeout(() => {
        setType("");
        setAmount(0);
        getWalletHistory();
        setSuccessModalShow(false);
      }, 2000);
    } else {
      setErrorModal({ show: true, heading: "Wallet", text: res?.message });
      setTimeout(() => {
        setErrorModal({ show: false, heading: "", text: "" });
      }, 2000);
    }
  }

  const Export = async () => {
    try {
      const response = await getExportStatement();
      // Create a URL for the blob
      const url = IMG_URL + response;
      console.log('url:', url);

      // Open the PDF in a new window
      window.open(url);

      // Cleanup
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting statement:', error);
    }
  };

  useEffect(() => {
    getWalletHistory();
  }, [currentPage]);



  const handlePageClick = (page) => {
    setCurrentPage(page);

  };

  const getPaginationItems = () => {
    let items = [];

    // Always show the first page
    items.push(
      <Pagination.Item
        key={1}
        active={currentPage === 1}
        onClick={() => handlePageClick(1)}
      >
        {1}
      </Pagination.Item>
    );

    // Add ellipsis if there is a gap between the first page and the range around current page
    if (currentPage > 4) {
      items.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    // Display 2 pages before and after the current page
    for (let i = Math.max(2, currentPage - 2); i <= Math.min(currentPage + 2, totalPages - 1); i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Add ellipsis if there is a gap between the last page and the range around current page
    if (currentPage < totalPages - 3) {
      items.push(<Pagination.Ellipsis key="end-ellipsis" />);
    }

    // Always show the last page
    if (totalPages > 1) {
      items.push(
        <Pagination.Item
          key={totalPages}
          active={currentPage === totalPages}
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };


  return (
    <>
      <section className="Walletdashboard">
        <div className="datatableMain">
          <div className="row">
            <div className="col-lg-4 ">

              <p>
                <b>Wallet Amount:</b> ₹ {affiliateWallet?.data?.wallet?.amount}
              </p>
            </div>

            <div className="col-lg-8 text-end">
              <div className="d-flex justify-content-end">
                <div className="">
                  <button className="Getrepbtnn btn" onClick={() => Export()}>
                    export
                  </button>
                  <button className="Getrepbtnn btn" onClick={() => addWallet('pay')}>
                    Pay
                  </button>
                  <button className="Getrepbtnn btn" onClick={() => addWallet('withdraw')}>
                    Withdraw
                  </button>
                  <button className="Getrepbtnn btn" onClick={() => getWalletHistory()}>
                    Refresh History
                  </button>
                </div>

              </div>
            </div>
          </div>


          <div className="wallet-table-sccc mt-4">
            <div className="table-responsive">
              <table className="table table-bordered table-responsive">
                <thead>
                  <tr>
                    <th className="wallselecttt">
                    Status
                    </th>
                    <th>Transaction Id</th>
                    <th>Date</th>
                    {/* <th>From</th> */}
                    {/* <th>To</th> */}
                    <th>Task Id</th>
                    <th>Amount</th>
                    <th>Note</th>
                  </tr>
                </thead>

                <tbody>
                  {affiliateWallet?.data?.data?.sort((a, b) => b.id - a.id).map(
                    (value, index) => (
                      <tr>
                        <td className={value?.transaction_type === 'debit' || value?.transaction_type === 'block' ? "cancc-app" : "compp-app"}>
                          {/* <FontAwesomeIcon
                            className={
                              value?.transaction_type === "debit"
                                ? "misscallicon"
                                : value?.transaction_type === "block"
                                  ? "blockcallicon"
                                  : "upcomimgcallicon"
                            }
                            icon={
                              value?.transaction_type === "debit"
                                ? "fa-solid fa-arrow-trend-up"
                                : value?.transaction_type === "block"
                                  ? "fa-solid fa-circle-stop"
                                  : "fa-solid fa-arrow-trend-down"
                            }
                          /> */}
                          <p>{value?.transaction_type}</p>
                        </td>
                        <td>{value?.razor_id}</td>
                        <td>{dateShow(value?.createdAt)}</td>

                        <td>{value?.seller_task?.task_id}</td>
                        <td>₹ {value?.amount}</td>
                        <td>{value?.remark}</td>
                        
                      </tr>
                    )
                  )}


                </tbody>
              </table>

              <div className="walletpaginationdiv">
                <Pagination>

                  {currentPage > 1 && (
                    <Pagination.Prev onClick={() => handlePageClick(currentPage - 1)} />
                  )}

                  {getPaginationItems()}


                  {currentPage < totalPages && (
                    <Pagination.Next onClick={() => handlePageClick(currentPage + 1)} />
                  )}
                </Pagination>

              </div>
            </div>
          </div>
        </div>
      </section>
      <ErrorModal
        show={errorModal.show}
        heading={errorModal.heading}
        text={errorModal.text}
      />

      <DepositModal show={amountModal} onHide={() => setAmountModal(false)} handleWithdraw={handleWithdraw} walletAmount={affiliateWallet?.wallet?.amount} depositAmount={depositAmount} setAmount={setAmount} handleRazorpay={handleRazorpay} type={type} />
      <SuccessModalDynamic show={successModalShow} onHide={() => setSuccessModalShow(false)} text={type === 'pay' ? "Amount Deposited to your wallet!" : "Withdraw request sent successfully!"} />
    </>
  );
};

export default Wallet;
