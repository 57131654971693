import React, { useContext, useEffect, useState } from "react";
import "../affi-events.css";
import { getEvents } from "../../../../../../utils/apis/events/event";
import { Context } from "../../../../../../utils/context";
import { Link } from "react-router-dom";

const InfluencerEvent = () => {
  const { IMG_URL, formatDate } = useContext(Context);
  const [events, setEvents] = useState();
  const getAllEvents = async () => {
    const res = await getEvents("influencer");
    if (res?.success) {
      setEvents(res?.data);
    }
  };
  useEffect(() => {
    getAllEvents();
  }, []);

  const formatEventDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    const formattedTime = date
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .replace(":00", "");
    return `${formattedDate} at ${formattedTime}`;
  };
  return (
    <>
      <section className="afffievents-sec">
        <div className="row">
          {events?.map((event) => (
            <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div
                className="eventcard"
                style={{
                  opacity: new Date(event?.end) <= new Date() ? 0.5 : 1,
                  color:
                    new Date(event?.end) <= new Date() ? "#fff" : "inherit",
                  backgroundColor:
                    new Date(event?.end) <= new Date() ? "#202020" : "inherit",
                }}
              >
                <div className="img-div">
                  <img className="cardimg" src={IMG_URL + event?.image} />
                </div>
                <div className="cardbody">
                  <p className="datep">
                    {" "}
                    {event?.start} to {event?.end}
                  </p>
                  <h6 className="cardtitle">{event?.title}</h6>
                  <p className="like">
                    {" "}
                    <span>Type : {event?.event_type}</span>
                    <span className="ps-2 pe-2">
                      |{" "}
                      {event?.event_type === "Online"
                        ? <Link target={new Date(event?.end) <= new Date() ? "" : "_blank"}  to={new Date(event?.end) <= new Date() ? '' : event?.event_link} className="btn btn-info">View Link</Link>
                        : event?.event_address}
                    </span>
                    <span></span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default InfluencerEvent;
