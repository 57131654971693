import React, { useState } from 'react'
import Tasktable from './Tasktable/Tasktable'
import Taskstock from './Taskstock/Taskstock'

const Orders = () => {

  

  
  const [products, setProduct] = useState({});
  return (
    <>


    
      <Taskstock products={products}/> 
      <Tasktable products={products} setProduct={setProduct}/>

    </>
  )
}

export default Orders