import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "./Subscription.css";
import { Button, Container, Form, Nav } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "swiper/modules";
import { Context } from "../../../../../../../../utils/context";
import useRazorpay from "react-razorpay";
import SuccessModalDynamic from "../../../../../../../CommonForAll/SuccessModalDynamic/SuccessModalDynamic";
import FormModal from "../../../../../../../CommonForAll/plan-form-modal/Form_modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "react-bootstrap/Table";
import { CFeatures, singleCountry } from "../../../../../../../../utils/apis/master/Master";

import Cookies from "js-cookie"
const Subscription = ({ setId, Id, planId, setPlanId }) => {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  // Array of subscription plans

  const { getData, IMG_URL, postData, isTokenValid,currency, setCurrency } = useContext(Context);
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [productDetails, setProductDetails] = useState();
  const [selectedPlan, setSelectedPlan] = useState("");
  const [formModal, setFormModal] = useState(false);
  const [tabId, setTabId] = useState();

  const [Features, setFeatures] = useState([]);
  const getProduct = async () => {
    const res = await getData(`/outer-website/packages`);
    if (res?.success) {
      setProductDetails(res?.data);
    } else {
    }

    {
      const res = await CFeatures();
      if (res?.success) {
        setFeatures(res?.data);
      }
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  function handlePayPalClick(id, plan_id) {
    setPlanId(plan_id);
    setId(id);
  }

  const [Razorpay, openRazorpay] = useRazorpay();
  const handleRazorpay = async (storedValue, id, domain, dns,domain_name) => {
   
    const options = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: currency?.international_status ? (Math.round((storedValue * 100) * currency?.exchange_rate  * (1 + selectedPlan?.international_percent/100)) ) : (Math.round((storedValue * 100) * currency?.exchange_rate)),
      currency: currency?.code,
      name: "Netpurti",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        // await setRazorId(res.razorpay_payment_id);
        finalFunction(res.razorpay_payment_id, id, domain, dns, storedValue,domain_name);
        console.log(res, "ttttttttttttttttttttttttttttt");
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  const finalFunction = async (razorpayid, id, domain, dns, storedValue,domain_name) => {

    //   paydata.plan_id=plan_id
    //   console.log(paydata,"paydata paydata");
    try {
        const paydata = {
            plan_id: id,
            razorpay_id: razorpayid,
            domain: domain,
            domain_name: domain_name,
            amount: storedValue,
            dns: dns,
        };

        const response = await postData(`/outer-website/packages/seller-plan`, paydata);
        if (response?.success) {
          setFormModal(false);
            setSuccessModalShow(true);
            setTimeout(() => {
              isTokenValid();
                setSuccessModalShow(false);
            }, 3000);
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};
  const handleamountid = async (price, plan_id) => {
    handleRazorpay(price, plan_id);
  };

  console.log(planId, "planId Id");
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [country, setCountry] = useState("India");

  const getCurrency = async () => {
      const res = await singleCountry(country);
      if(res?.success){
          setCurrency(res?.data?.currency);
      }
  }

    useEffect(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            // Fetch country name using reverse geocoding
            fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`)
              .then(response => response.json())
              .then(data => {
                setCountry(data.address.country);
              })
              .catch(error => {
                console.error('Error fetching country:', error);
              });
          },
          (error) => {
            console.error('Error getting location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
              getCurrency();
    }, [country]);

  return (
    <section className="subscription-class-seller">
      <Container>
        <Swiper
          pagination={true}
          modules={[Pagination]}
          // modules={[A11y]}
          slidesPerView={4}
          spaceBetween={30}
          className="pricing-slider"
          breakpoints={{
            0: { slidesPerView: 1 },
            380: { slidesPerView: 1 },
            485: { slidesPerView: 1 },
            575: { slidesPerView: 1 },
            768: { slidesPerView: 1 },
            992: { slidesPerView: 2 },
            1024: { slidesPerView: 2 },
            1200: { slidesPerView: 4 },
            1440: { slidesPerView: 4 },
            2000: { slidesPerView: 4 },
          }}
        >
          {productDetails?.map((plan, index) => (
            <SwiperSlide key={index}>
              <div className="card pricingcard">
                <div className="card-body">
                  <div className="subscriptionhead">
                    <h3>{plan?.name}</h3>
                    <p>{currency?.symbol} {currency?.international_status ? (Math.round(plan?.selling_price * currency?.exchange_rate  * (1 + plan?.international_percent/100)) ) : (Math.round(plan?.selling_price * currency?.exchange_rate))}</p>
                  </div>

                  <div>
                    <ul className="pricingList">
                      {plan?.m_w_plan_features?.map((feat, feaindex) => (
                        <li>
                          <span className="rightTick">
                            <FontAwesomeIcon icon="fa-solid fa-check" />
                          </span>
                          {feat?.m_w_s_feature?.name}
                        </li>
                      ))}

                      {/* <li><span className="rightTick"><FontAwesomeIcon icon="fa-solid fa-check" /></span>20GB Cloud storage</li>
                                            <li><span className="rightTick"><FontAwesomeIcon icon="fa-solid fa-check" /></span>Integration help</li>
                                            <li><span className="rightTick"><FontAwesomeIcon icon="fa-solid fa-check" /></span>Sketch Files</li>
                                            <li><span className="rightTick"><FontAwesomeIcon icon="fa-solid fa-check" /></span>Sketch Files</li>
                                            <li><span className="rightTick"><FontAwesomeIcon icon="fa-solid fa-check" /></span>Sketch Files</li>
                                            <li><span className="minusTick"><FontAwesomeIcon icon="fa-solid fa-minus" /></span>Sketch Files</li> */}
                    </ul>
                  </div>

                  <div>
                    <Link to={`/seller/seller-dashboard/themepage/${plan?.id}`}>
                      <Button type="button" className="joinbutton">
                        View Plan{" "}
                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="subcsriptionMain">
          <div className="card subscriptioncard">
            <div className="row subscriptrow">
              <div className="col-lg-3">
                <svg
                  width="213"
                  height="188"
                  viewBox="0 0 213 188"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="75"
                    cy="50"
                    r="138"
                    fill="url(#paint0_linear)"
                  ></circle>
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="75"
                      y1="-88"
                      x2="75"
                      y2="188"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#3056D3" stop-opacity="0.15"></stop>
                      <stop
                        offset="1"
                        stop-color="#C4C4C4"
                        stop-opacity="0"
                      ></stop>
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="col-lg-9">
                <div className="row pricingrowmain">
                  {productDetails?.map((plan, index) => (
                    <div className="col-lg-4">
                      <div className="subscriptCon">
                        <h3>{plan?.name}</h3>
                        <div className="subscriptionPrice">
                          <span>{currency?.symbol} {currency?.international_status ? (Math.round(plan?.selling_price * currency?.exchange_rate * (1 + plan?.international_percent/100)) ) : (Math.round(plan?.selling_price * currency?.exchange_rate))}</span>
                          {/* <span>Per Month</span> */}
                        </div>
                        <p className="subscriptInfo">
                          Best suited for freelancers who works individually.
                        </p>
                        <div>
                          <button
                            className="btn btn-primary purchasebtn"
                            type="button"
                            onClick={() => { setSelectedPlan(plan); setFormModal(true); }}
                          >
                            Purchase Now
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* <div className="col-lg-3">
                                        <div className="subscriptCon">
                                            <h3>Starter</h3>
                                            <div className="subscriptionPrice">
                                                <span>$59</span>
                                                <span>Per Month</span>
                                            </div>
                                            <p className="subscriptInfo">Best suited for freelancers who works individually.</p>
                                            <div>
                                                <button className="btn btn-primary purchasebtn" type="button">Purchase Now</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="subscriptCon">
                                            <h3>Starter</h3>
                                            <div className="subscriptionPrice">
                                                <span>$59</span>
                                                <span>Per Month</span>
                                            </div>
                                            <p className="subscriptInfo">Best suited for freelancers who works individually.</p>
                                            <div>
                                                <button className="btn btn-primary purchasebtn" type="button">Purchase Now</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="subscriptCon">
                                            <h3>Starter</h3>
                                            <div className="subscriptionPrice">
                                                <span>$59</span>
                                                <span>Per Month</span>
                                            </div>
                                            <p className="subscriptInfo">Best suited for freelancers who works individually.</p>
                                            <div>
                                                <button className="btn btn-primary purchasebtn" type="button">Purchase Now</button>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>

            <div className="pricingtablemain">
              <Table bordered className="no-vertical-borders">
                <thead>
                  <tr>
                    <th>Key Features</th>
                    <th width={340}>Features </th>
                    <th width={340}>Features </th>
                    <th width={340}>Features </th>
                    <th width={240}> </th>
                  </tr>
                </thead>

                <tbody>
                  {Features?.map((feature, index) =>
                    feature?.m_w_s_features?.map(
                      (m_w_s_features_value, m_w_s_features_index) => (
                        <tr>
                          <td>{m_w_s_features_value?.name}</td>

                          {productDetails?.map((value) => (
                            <td>
                              {value?.m_w_plan_features.some(
                                (item) =>
                                  item.m_w_s_features_id ===
                                  m_w_s_features_value?.id
                              ) ? (
                                <div>
                                  <span className="rightTicktwo">
                                    <FontAwesomeIcon icon="fa-solid fa-check" />
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  <span className="crossTicktwo">
                                    <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                  </span>
                                </div>
                              )}
                            </td>
                          ))}
                        </tr>
                      )
                    )
                  )}

                  {/* <tr>
                                        <td>2</td>
                                        <td>Jacob</td>
                                        <td>Thornton</td>
                                        <td>Thornton</td>
                                        <td>
                                            <div>
                                                <span className="rightTicktwo"><FontAwesomeIcon icon="fa-solid fa-check" /></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td >Larry the Bird</td>
                                        <td>@twitter</td>
                                        <td>@twitter</td>
                                        <td>
                                            <div>
                                                <span className="crossTicktwo"><FontAwesomeIcon icon="fa-solid fa-xmark" /></span>
                                            </div>
                                        </td>
                                    </tr> */}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Container>
      <SuccessModalDynamic show={successModalShow} onHide={() => setSuccessModalShow(false)} text={"Plan Purchase SuccessFully"} />
      <FormModal show={formModal} onHide={() => setFormModal(false)} selectedPlan={selectedPlan} handleRazorpay={handleRazorpay} />
    </section>
  );
};

export default Subscription;
