import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../api";
  

  export const getNotifications = async (type) => {
    try {
  
            
        let url = `/notifications`;

            url += `?type=${type}`;
        
      const res = await getData(url);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };