import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import "../../B2b_login/B2b_login.css";
import ButtonComponent from "../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import { Context } from "../../../../utils/context";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../../../utils/common";
import { btobLogin } from "../../../../utils/apis/btb/B2b";
import Cookies from "js-cookie";
import { loginCart } from "../../../../utils/apis/btb/Cart";

const B2bMainLogin = () => {
  const { IMG_URL, setSignin, setUsertype } = useContext(Context);
  const [showPassword, setShowPassword] = useState(false);
  const [iconOne, setIconOne] = useState(faEyeSlash);
  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    }
  };
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (d) => {
    // console.log(d);

    const res = await btobLogin(d);
    if (res?.success) {
      //   console.log(res);
      Cookies.set("net_purti_security", res.data.token, { expires: 1 });
      setUsertype(res.data?.type);
      setSignin(true);
      loginCart();
      navigate("/b2bbuyer/buyer-home");
    } else {
      setError("password", {
        type: "server",
        message: res?.message,
      });
    }
  };

  //   console.log(errors);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="B2b-Login">
        <div className="container">
          <div className="loginHead">
            <Link to={"/b2bbuyer/buyer-home"}>
              <p>
                <FontAwesomeIcon
                  icon="fa-solid fa-less-than"
                  className="lessThanSymbol"
                />
                Go to Home
              </p>
            </Link>
            <h3>B2B Buyer Login</h3>
            <p></p>
          </div>

          <div className="col-lg-6 mx-auto">
            <div className="personal-details-form-b2b ">
              {/* form-holder */}

              <div className="details-form-holder">
                <div className="form-container">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="field-bottom">
                            <Form.Group controlId="Last_Name">
                              <Form.Label className="required form-field">
                                Email
                              </Form.Label>
                              <div className="get-otp-text">
                                <Form.Control
                                  type="text"
                                  name="email_or_contact"
                                  placeholder="Enter Email"
                                  {...register("email_or_contact", {
                                    required: "Email is required",
                                    pattern: {
                                      value: RegxExpression.email,
                                      message: "Invalid email address",
                                    },
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.email_or_contact,
                                  })}
                                />
                              </div>
                              {errors?.email_or_contact && (
                                <span className="text text-danger">
                                  {errors.email_or_contact.message}
                                </span>
                              )}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="field-bottom">
                            <Form.Group controlId="Last_Name">
                              <div className="password-field-holder">
                                <Form.Label className="required form-field">
                                  Password
                                </Form.Label>

                                <div className="get-otp-text">
                                  <Form.Control
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Enter Password*"
                                    className={classNames("Passwordfield", {
                                      "is-invalid": errors?.password,
                                    })}
                                    name="password"
                                    {...register("password", {
                                      required: "Password is required",
                                    })}
                                  />

                                  <div className={classNames("eye-icon-holder", {
                                    "error-long-message": errors?.password?.message?.length > 12,
                                  })}>
                                    <FontAwesomeIcon
                                      icon={iconOne}
                                      className="StepsixeditIcon"
                                      onClick={() =>
                                        togglePasswordVisibility("password")
                                      }
                                    />
                                    
                                  </div>


                                  {/* <p >
                                                                    Forget password ?
                                                                </p> */}
                                  <Link to="/b2bbuyer/forgetpassword">
                                    {" "}
                                    Forget password ?
                                  </Link>
                                  {errors?.password && (
                                    <span className="text text-danger">
                                      {errors.password?.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="text-center ">
                            <ButtonComponent
                              type="submit"
                              className="tabs-btn login_now_btn mb-2"
                            >
                              Login
                            </ButtonComponent>
                            <div className="bottom-d-v">
                              <span className="span-one">
                                Don't have an account?
                              </span>
                              <Link to={"/b2bbuyer/b2bstepform"}> Sign Up</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default B2bMainLogin;
