export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);

  return a;
};

export const Product_Type = [
  {
    id: 1,
    name: "Customization & Packaging",
    tag: "OD-PCWP",
    rename: "Customize Goods & Packaging",
  },
  { id: 2, name: "Supply-C", tag: "Supply-C", rename: "Supply-C" },
  {
    id: 3,
    name: "On-Demand Product",
    tag: "ODP",
    rename: "On-Demand Product",
  },
  { id: 4, name: "General", tag: "General", rename: "General" },
];

export const RequiredIs = {
  roles: [1, 2, 3, 4, 5, 6, 7],
  product_type: {
    customize: 1,
    supply: 2,
    demand: 3,
    ganeral: 4,
  },
  working_type: {
    1: [],
    2: [0],
  },
  working_type_amp: [1, 3],

  inquery: [1, 2],
};

export const Description_Length = {
  about: 60,
  warranty_terms: 60,
};

export const IDS = {
  web_site_type: {
    admin: "admin",
    outer: "outer",
    info: "info",
    inquiry: "inquiry",
  },
  btb_order_status: {
    ordered: 1,
    processing: 2,
    shipped: 3,
    delivered: 4,
    cancelled: 5,
    returned: 6,
    time_out: 7,
  },
  working_type: {
    manufacturer: 1,
    supplier: 2,
    farmer: 3,
  },
};

export const UserType = {
  Seller: "Seller",
  BTB: "BTB",
  BTC: "BTC",
  MyWebsite: "MyWebsite",
};

export const InputeType = {
  1: {
    value: /^[0-9,]*$/,
    message: "Only numbers are allowed",
  },
  2: {
    value: /[A-Z-a-z,]/,
    message: "Only Charector are allowed",
  },
  3: {
    value: /^[-+]?[0-9,]*\.[0-9]+$/,
    message: "Only Numbers and Character are allowed",
  },
  // 4: /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,
  4: {
    value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*,])[A-Za-z\d!@#$%^&*,]{8,}$/,
    message: "Only Special Characters are allowed",
  },
  5: {
    value: /[\s\S]*/g,
    message: "Accept All",
  },
};

export const Paths = {
  o_w_seller_product: "/seller/seller-dashboard/sellerlistnewproduct",
  o_w_seller_product_edit: "/seller/seller-dashboard/sellerproduct",
  info_seller_product: "/seller/seller-dashboard/sellerlistnewproduct",
  btb_seller_product: "/seller/seller-dashboard/b2b-products",
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const Select2CurrencyData = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name + " " + data?.symbol,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const Select2CategoryData = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.category_id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const Select2SubCategoryData = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.sub_category_id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};
export const Select2AttributeData = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,

    label: data?.defind ? data?.name + " :" + data?.defind : data?.name,
    input_type_id: data?.input_type_id,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const SelectHsnData = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label:
      data?.name + " | " + data?.gst + "%" + " | " + data?.product_narration,
    name: name,
    percent: data?.gst,
    below: data?.below,
    gst_narration: data?.gst_narration,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const Select3Data = async (data, product_title, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.product_title,
    name: product_title,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: product_title });
  }
  return result;
};

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};

export const ProductNarrationChange = (e) => {
  const { value } = e.target;
  const capitalizedValue = value
    .split(" ")
    .map((word) => word.trim())
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return capitalizedValue;
};
export const ProductNarrationChangeComa = (e) => {
  const { value } = e.target;
  const capitalizedValue = value
    .split(",")
    .map((word) => word.trim())
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(",");
  return capitalizedValue;
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
}

export function formatDate2(dateString) {
  return new Date(dateString).toLocaleString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
}

export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function dateShow(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
// Function to format the date and add days
export function formatDateExpire(dateString, daysToAdd = 0) {
  if (dateString) {
    const date = new Date(dateString);
    const newDate = addDays(date, daysToAdd);
    return newDate.toISOString().split("T")[0];
  }
}

export const RegxExpression = {
  name: /[A-Z-a-z ]/,
  email:
    /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
  phone: /[0-9]/,
  number: /^[0-9]*$/,
  ifsc: "^[A-Z]{4}[0][dA-Z]{6}$",
  ifsc_code: /^[A-Z0-9]$/,
  micr: /^[0-9]{9}$/,
  bank_number: /^\d{0,14}$/,
  gst: /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[1-9A-Z]{1}$/,
};

export const validateImage = async (file, size = 500) => {
  if (!file) {
    console.log("Image is required");
    return false;
  }

  return true;
  // if (!file) return "Image is required";

  // Check file size (500KB = 500 * 1024 bytes)
  const maxSizeInBytes = size * 1024;
  if (file.size > maxSizeInBytes) {
    console.log("Image size must be less than or equal to 500KB");
    return false;
    // return "Image size must be less than or equal to 500KB";
  }

  return new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      console.log(img.width);
      console.log(img.width);
      console.log(aspectRatio.toFixed(1));
      if (aspectRatio.toFixed(1) == 0.5) {
        resolve(true);
      } else {
        console.log("Image aspect ratio must be 1:2 (width:height)");
        resolve(false);
        // resolve("Image aspect ratio must be 1:2 (width:height)");
      }
    };
    img.onerror = () => {
      console.log("Invalid image file");
      resolve(false);
      // resolve("Invalid image file");
    };
  });
};

export const validateVideo = async (file, size = 5, duration = 40) => {
  return new Promise((resolve) => {
    if (!file) {
      console.log("Please upload a video file.");
      resolve(false);
      return;
    }

    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const v_duration = video.duration;
      const v_size = file.size / (1024 * 1024); // size in MB

      console.log(v_duration.toFixed(0));
      console.log(v_size.toFixed(0));

      if (v_duration > duration) {
        console.log("The video duration exceeds 40 seconds.");
        resolve(false);
      } else if (v_size > size) {
        console.log("The video size exceeds 5 MB.");
        resolve(false);
      } else {
        console.log("The video is valid.");
        resolve(true);
      }
    };

    video.src = URL.createObjectURL(file);
  });
};

export const formatDateObject = (dateString) => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, "0"); // Get day and add leading zero if needed
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month and add leading zero
  const year = date.getFullYear(); // Get full year

  return `${day}-${month}-${year}`; // Format as DD-MM-YYYY
};


export const timeAgo = (date) => {
  const now = new Date();
  const diffInSeconds = Math.floor((now - new Date(date)) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  
  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minutes ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hours ago`;
  } else {
    return `${diffInDays} days ago`;
  }
};
