import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../../api";
  

  export const getSellerCalendar = async (selectedDate) => {
    try {
  

        let url = `/seller/seller-calendar/`;

        if(selectedDate){
            url += `?selectedDate=${selectedDate}`;
        }
      const res = await getData(url);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  
  
  
  
  
  