import React, { useState, useEffect, useContext, useMemo } from "react";
// import "./b2b-table.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { motion } from "framer-motion";

import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { AnimatePresence } from "framer-motion";
import { ShimmerTable } from "react-shimmer-effects";
import SuccessModalDynamic from "../../../../../CommonForAll/SuccessModalDynamic/SuccessModalDynamic";

// import {
//     getAllProducts,
//     productStatusChange,
//     historiesGet,
// } from "../../../../../../../utils/apis/seller/Product";

// import {
//     ProductStatus,
//     Category,
// } from "../../../../../../../utils/apis/master/Master";
import { type } from "@testing-library/user-event/dist/type";
import { Context } from "../../../../../../utils/context";
import CustomPaginate from "../../../../../CommonForAll/CustomPaginate/CustomPaginate";
import VeiwEditModal from "../../../../../CommonForAll/VeiwEditModal/VeiwEditModal";
import Mrp_modal from "../../../../../CommonForAll/mrp-modal/Mrp_modal";

import { getAffiliateTracking } from "../../../../../../utils/apis/affiliate/Tracking/traking/task";
import {
  Category,
  ProductStatus,
} from "../../../../../../utils/apis/master/Master";
import CategoryDetails from "../../../../../CommonForAll/CategoryDetails/CategoryDetails";
import View_all_varients_offcanvas from "../../../../Seller_Dashboard/common-components/offcanvas/view-all-varients-offcanvas/View_all_varients_offcanvas";
import { taskStatusChange } from "../../../../../../utils/apis/affiliate/affiliate";

const Ordertable = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { shimmerLoader, setShimmerLoader, formatDateExpire, formatDate } =
    useContext(Context);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [productId, setProductId] = useState("");

  const [copyModal, setCopyModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modaleye, setModaleye] = useState(false);
  const [modalShowedit, setModalShowedit] = useState(false);
  const [varientsoffcanvas, setVarientsoffcanvas] = useState(false);
  const [productTitle, setProductTitle] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [category, setCategory] = useState("");
  const [varientType, setVarientType] = useState("");
  const [key, setKey] = useState("home");
  const [deleteshow, setdeleteShow] = useState(false);

  const [show1, setShow1] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const InitialTransition = () => {};
  const blackBox = {
    initial: {
      height: "100vh",
    },
  };

  const { IMG_URL, userdata } = useContext(Context);
  const [products, setProducts] = useState([]);

  const getProducts = async (page) => {
    setShimmerLoader(true);
    const res = await getAffiliateTracking(
      page,
      productTitle,
      searchStatus,
      category,
      startDate,
      endDate
    );
    if (res?.success) {
      console.log(res);
      const newData = res?.data?.data;
      if (newData) {
        const newIds = newData.map((d) => d?.id);
        setAllChecked(newIds);
      }
      setProducts(res.data);
      setShimmerLoader(false);
    }
  };
  const [varIndex, setIndex] = useState("");
  const [variantData, setVariantData] = useState();
  const handleVariantModal = async (data, index) => {
    await setIndex(index);
    await setVariantData(data);
    setVarientsoffcanvas(true);
  };

  const [productStatus, setProductStatus] = useState([]);
  const [categories, setCategories] = useState([]);
  const getMasters = async () => {
    {
      const res = await ProductStatus();
      if (res?.success) {
        setProductStatus(res?.data);
      }
    }
    {
      const res = await Category(userdata?.s_sector?.s_working_type_id);
      if (res?.success) {
        setCategories(res?.data);
      }
    }
  };

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  const handleTaskStatus = async (id, status) => {
    const res = await taskStatusChange(id, status);
    if (res?.success) {
      getProducts(currentPage);
    }
  };

  const copyToClipboard = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopyModal(true);
        setTimeout(() => {
          setCopyModal(false);
        }, 2000);
        console.log("URL copied to clipboard:", url);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const hashNavigate = async (url, atid, a_id = "") => {
    if (a_id !== "") {
      // history.push(url, {
      //   atid: atid,
      //   a_id: a_id
      // });
    } else {
      // history.push(url, {
      //   atid: atid
      // });
    }
  };
  const [sortBy, setSortBy] = useState(""); // To store the sort criteria

  // Sort the data based on the selected criteria
  const sortedProducts = useMemo(() => {
    if (!products?.data) return [];
    
    let sortedData = [...products.data]; // Make a copy of the data

    switch (sortBy) {
      case "paid":
        sortedData.sort((a, b) => {
          // Sort by Paid status, assuming Paid items come before others
          const statusA = a?.affiliate_status === "accepted" && a?.affiliate_task_group?.pyd_datum;
          const statusB = b?.affiliate_status === "accepted" && new Date(b?.seller_task?.validity) < new Date();
          
          return statusB - statusA; // Sort "Paid" (true) before "Not Paid" (false)
        });
        break;
      case "transaction_in_process":
        sortedData.sort((a, b) => {
          // Sort by Transaction In Process status
          const statusA = a?.affiliate_status === "accepted" && new Date(a?.seller_task?.validity) < new Date();
          const statusB = b?.affiliate_status === "accepted" && b?.affiliate_task_group?.pyd_datum;
          return statusB - statusA; // Sort "Transaction In Process" (true) before others
        });
        break;
      default:
        break;
    }
    return sortedData;
  }, [sortBy, products?.data]);
  useEffect(() => {
    getMasters();
    getProducts(currentPage);
  }, [currentPage, productTitle, searchStatus, category, startDate, endDate]);

  return (
    <>
      <section className="B2b_table_section">
        <AnimatePresence />
        <h6 className="pdlsis">Tracking</h6>
        <div className="product-main">
          <div className="search-main">
            <Form>
              <div className="row">
                <div className="col-xxl-5 co-xl-5 col-lg-3 col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      value={productTitle}
                      type="search"
                      placeholder="Search by Adv ID"
                      onChange={(e) => setProductTitle(e.target.value)}
                    />
                    <FontAwesomeIcon
                      className="serch-icoo"
                      icon="fa-solid fa-magnifying-glass"
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-3 co-xl-3 col-lg-3 col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Sort By</Form.Label>
                    <Form.Select
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                      aria-label="Sort By"
                    >
                      <option value="">Select Sort Criteria</option>
                      <option value="paid">Paid</option>
                      <option value="transaction_in_process">
                        Transaction In Process
                      </option>
                    </Form.Select>
                  </Form.Group>
                </div>

                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Reset Search</Form.Label>
                    <button
                      className="resetsearch-btn"
                      type="button"
                      onClick={() => {
                        // setSearchStatus("");
                        setProductTitle("");
                        setStartDate("");
                        setEndDate("");
                        // setCategory("");
                      }}
                    >
                      Reset
                    </button>
                  </Form.Group>
                </div>

                {/* <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Download data in Excel</Form.Label>
                    <div>
                      <img
                        className="excelimg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/dashboard//icons/excel.png"
                        }
                      />
                    </div>
                  </Form.Group>
                </div> */}
              </div>

              <hr></hr>

              <div className="table-responsive table-overflow">
                {shimmerLoader ? (
                  <>
                    <ShimmerTable row={1} col={12} />
                  </>
                ) : (
                  <>
                    <table className="table table-bordered">
                      <thead>
                        <tr className="">
                          <td>Adv ID</td>

                          <td>Type</td>

                          <td>Insentive</td>

                          <td>Validity</td>

                          <td>Product Type</td>
                          <td>Product </td>

                          <td>Task Created</td>
                          <td>Status</td>
                        </tr>
                      </thead>

                      <tbody>
                        {sortedProducts?.map((val, index) => (
                          <tr>
                            <td> {val?.seller_task?.task_id}</td>

                            <td>{val?.seller_task?.task_type}</td>
                            <td>
                              {val?.seller_task?.product_type === "Website"
                                ? Math.round(val?.seller_task?.insentive) + " %"
                                : "₹ " + val?.seller_task?.insentive}
                            </td>

                            <td>
                              {formatDateExpire(
                                val?.createdAt,
                                val?.seller_task?.validity
                              )}
                            </td>
                            <td>{val?.seller_task?.product_type}</td>
                            <td>
                              {val?.seller_task?.s_product?.product_title}
                            </td>

                            <td>
                              {val?.createdAt &&
                                new Date(val.createdAt).toLocaleString(
                                  "en-US",
                                  {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                  }
                                )}
                            </td>

                            <td>
                              {val?.affiliate_status === "accepted" ? (
                                val?.seller_task?.strick_status ? (
                                  <span style={{ color: "green" }}>
                                    {
                                      val?.seller_task?.strick_status
                                        ?.strick_status
                                    }
                                  </span>
                                ) : val?.affiliate_task_group?.pyd_datum ? (
                                  <span style={{ color: "green" }}>Paid</span>
                                ) : new Date(val?.seller_task?.validity) <
                                  new Date() ? (
                                  <span style={{ color: "blue" }}>
                                    Transaction In Process
                                  </span>
                                ) : (
                                  <span style={{ color: "green" }}>
                                    {val?.affiliate_status}
                                  </span>
                                )
                              ) : (
                                <span style={{ color: "red" }}>
                                  {val?.affiliate_status}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </Form>
          </div>

          <CategoryDetails show={modaleye} onHide={() => setModaleye(false)} />

          <div className="pagination-div">
            <CustomPaginate
              currentPage={currentPage}
              totalPages={products?.totalPages}
              handlePageChange={handlePageChange}
            />
          </div>

          <View_all_varients_offcanvas
            show={varientsoffcanvas}
            variantData={variantData}
            onHide={() => setVarientsoffcanvas(false)}
            placement="top"
          />

          <VeiwEditModal
            show={modalShowedit}
            varientType={varientType}
            productId={productId}
            type={type}
            onHide={() => setModalShowedit(false)}
          />

          <SuccessModalDynamic
            show={copyModal}
            onHide={() => setCopyModal(false)}
            text={"Link Copied"}
          />
          <Mrp_modal show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </section>
    </>
  );
};

export default Ordertable;

// import React, { useState, useEffect, useContext } from "react";
// // import "./b2b-table.css";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { motion } from "framer-motion";

// import Form from "react-bootstrap/Form";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from "react-router-dom";
// import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
// import Tab from "react-bootstrap/Tab";
// import Tabs from "react-bootstrap/Tabs";
// import { AnimatePresence } from "framer-motion";

// // import {
// //     getAllProducts,
// //     productStatusChange,
// //     historiesGet,
// // } from "../../../../../../../utils/apis/seller/Product";

// // import {
// //     ProductStatus,
// //     Category,
// // } from "../../../../../../../utils/apis/master/Master";
// import { ShimmerTable } from "react-shimmer-effects";
// import { type } from "@testing-library/user-event/dist/type";
// import { Context } from "../../../../../../utils/context";
// import CustomPaginate from "../../../../../CommonForAll/CustomPaginate/CustomPaginate";
// import VeiwEditModal from "../../../../../CommonForAll/VeiwEditModal/VeiwEditModal";
// import Mrp_modal from "../../../../../CommonForAll/mrp-modal/Mrp_modal";

// import {
//   getAllProducts,
//   productStatusChange,
// } from "../../../../../../utils/apis/seller/Product";
// import {
//   Category,
//   ProductStatus,
// } from "../../../../../../utils/apis/master/Master";
// import CategoryDetails from "../../../../../CommonForAll/CategoryDetails/CategoryDetails";
// import View_all_varients_offcanvas from "../../../../Seller_Dashboard/common-components/offcanvas/view-all-varients-offcanvas/View_all_varients_offcanvas";

// const Ordertable = (props) => {
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");

//   const { shimmerLoader, setShimmerLoader } = useContext(Context);

//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const [productId, setProductId] = useState("");

//   const [modalShow, setModalShow] = useState(false);
//   const [modaleye, setModaleye] = useState(false);
//   const [modalShowedit, setModalShowedit] = useState(false);
//   const [varientsoffcanvas, setVarientsoffcanvas] = useState(false);
//   const [productTitle, setProductTitle] = useState("");
//   const [searchStatus, setSearchStatus] = useState("");
//   const [category, setCategory] = useState("");
//   const [varientType, setVarientType] = useState("");
//   const [key, setKey] = useState("home");
//   const [deleteshow, setdeleteShow] = useState(false);

//   const [show1, setShow1] = useState(false);

//   const [currentPage, setCurrentPage] = useState(1);

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const InitialTransition = () => {};
//   const blackBox = {
//     initial: {
//       height: "100vh",
//     },
//   };

//   const { IMG_URL, userdata } = useContext(Context);
//   const [products, setProducts] = useState([]);

//   const getProducts = async (page) => {
//     setShimmerLoader(true);
//     const res = await getAllProducts(
//       page,
//       productTitle,
//       searchStatus,
//       category,
//       startDate,
//       endDate
//     );
//     if (res?.success) {
//       console.log(res);
//       const newData = res?.data?.data;
//       if (newData) {
//         const newIds = newData.map((d) => d?.id);
//         setAllChecked(newIds);
//       }
//       setProducts(res.data);
//       setShimmerLoader(false);
//     }
//   };
//   const [varIndex, setIndex] = useState("");
//   const [variantData, setVariantData] = useState();
//   const handleVariantModal = async (data, index) => {
//     await setIndex(index);
//     await setVariantData(data);
//     setVarientsoffcanvas(true);
//   };

//   const getVariantProducts = async () => {
//     const res = await getAllProducts(currentPage);
//     if (res?.success) {
//       console.log(res);
//       setProducts(res?.data);
//       await setVariantData(res?.data?.data[varIndex]);
//     }
//   };

//   const handleStatus = async (id) => {
//     setShimmerLoader(true);
//     const res = await productStatusChange(id);
//     if (res?.success) {
//       getProducts(currentPage);
//       setShimmerLoader(false);
//     }
//   };

//   const [productStatus, setProductStatus] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const getMasters = async () => {
//     {
//       const res = await ProductStatus();
//       if (res?.success) {
//         setProductStatus(res?.data);
//       }
//     }
//     {
//       const res = await Category(userdata?.s_sector?.s_working_type_id);
//       if (res?.success) {
//         setCategories(res?.data);
//       }
//     }
//   };

//   const [selectAllChecked, setSelectAllChecked] = useState([]);
//   const [allChecked, setAllChecked] = useState([]);

//   const handleSelectAll = async () => {
//     await setSelectAllChecked(allChecked);
//   };

//   const handleChange = async (e) => {
//     const { value, checked } = e.target;

//     if (value === "selectAll") {
//       if (checked) {
//         handleSelectAll();
//       } else {
//         await setSelectAllChecked([]);
//       }
//     } else {
//       if (checked) {
//         await setSelectAllChecked([...selectAllChecked, Number(value)]);
//       } else {
//         await setSelectAllChecked(
//           selectAllChecked.filter((e) => e !== Number(value))
//         );
//       }
//     }
//   };

//   console.log(selectAllChecked, "ttt");

//   const changeStatus = async () => {
//     setShimmerLoader(true);

//     if (selectAllChecked.length > 0) {
//       for (const value of selectAllChecked) {
//         await productStatusChange(value);
//       }
//     }

//     setShimmerLoader(false);
//     getProducts(currentPage);
//   };

//   useEffect(() => {
//     getMasters();
//     getProducts(currentPage);
//   }, [currentPage, productTitle, searchStatus, category, startDate, endDate]);

//   return (
//     <>
//       <section className="B2b_table_section">
//         <AnimatePresence />
//         <h6 className="pdlsis">Tracking</h6>
//         <div className="product-main">
//           <div className="search-main">
//             <Form>
//               <div className="row">
//                 <div className="col-xxl-2 co-xl-2 col-lg-3 col-md-6">
//                   <Form.Group className="form-group">
//                     <Form.Label>Search</Form.Label>
//                     <Form.Control
//                       type="search"
//                       placeholder="Search by product title"
//                       onChange={(e) => setProductTitle(e.target.value)}
//                     />
//                     <FontAwesomeIcon
//                       className="serch-icoo"
//                       icon="fa-solid fa-magnifying-glass"
//                     />
//                   </Form.Group>
//                 </div>
//                 <div className="col-xxl-5 co-xl-5 col-lg-6 col-md-6">
//                   <div className="d-flex product-status-div">
//                     <div className="sec2">
//                       <Form.Group className="form-group">
//                         <Form.Label>From</Form.Label>
//                         <DatePicker
//                           selected={startDate}
//                           onChange={(date) => setStartDate(date)}
//                           name="start_date"
//                           id="start_date"
//                           autoComplete="off"
//                           dateFormat="dd/MM/yyyy"
//                           className="form-control"
//                           placeholderText="DD/MM/YYYY"
//                         />
//                       </Form.Group>
//                     </div>
//                     <div className="sec2">
//                       <Form.Group className="form-group">
//                         <Form.Label>To</Form.Label>
//                         <DatePicker
//                           selected={endDate}
//                           onChange={(date) => setEndDate(date)}
//                           name="end_date"
//                           id="end_date"
//                           autoComplete="off"
//                           dateFormat="dd/MM/yyyy" // Set date format
//                           className="form-control"
//                           placeholderText="DD/MM/YYYY" // Update placeholder
//                         />
//                       </Form.Group>
//                     </div>
//                   </div>
//                 </div>

//                 {/* <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3">
//                   <Form.Group className="form-group">
//                     <Form.Label>Download data in Excel</Form.Label>
//                     <div>
//                       <img
//                         className="excelimg"
//                         src={
//                           process.env.PUBLIC_URL +
//                           "/assest/images/Seller_panel/dashboard//icons/excel.png"
//                         }
//                       />
//                     </div>
//                   </Form.Group>
//                 </div> */}
//               </div>

//               <hr></hr>

//               <div className="table-responsive table-overflow">
//                 <table className="table table-bordered">
//                   <thead>
//                     <tr className="">
//                       <td>Adv ID</td>

//                       <td>Date & Time</td>

//                       <td>Expiry</td>

//                       <td>Category</td>

//                       <td>Item Name</td>

//                       <td>Incentive On</td>

//                       <td>Total Click</td>

//                       <td>Conversion</td>

//                       <td>Earning Amount</td>

//                       <td>Status</td>
//                     </tr>
//                   </thead>

//                   <tbody>
//                     <tr>
//                       <td> 1527</td>

//                       <td>WR suresh</td>
//                       <td>#1122</td>

//                       <td>*******678</td>

//                       <td>1286.2</td>
//                       <td>100 </td>
//                       <td>106 (9%)</td>
//                       <td>200</td>

//                       <td>5165.4</td>

//                       <td>
//                         <Button className="acceptbtn" type="button">
//                           Paid
//                         </Button>
//                       </td>
//                     </tr>

//                     <tr>
//                       <td> 1527</td>

//                       <td>WR suresh</td>
//                       <td>#1122</td>

//                       <td>*******678</td>

//                       <td>1286.2</td>
//                       <td>100 </td>
//                       <td>106 (9%)</td>
//                       <td>200</td>

//                       <td>5165.4</td>

//                       <td>
//                         <Button className="rejectbtn" type="button">
//                           Pending
//                         </Button>
//                       </td>
//                     </tr>

//                     <tr>
//                       <td> 1527</td>

//                       <td>WR suresh</td>
//                       <td>#1122</td>

//                       <td>*******678</td>

//                       <td>1286.2</td>
//                       <td>100 </td>
//                       <td>106 (9%)</td>
//                       <td>200</td>

//                       <td>5165.4</td>

//                       <td>
//                         <Button className="progressbtn" type="button">
//                           Transaction In Process
//                         </Button>
//                       </td>
//                     </tr>
//                   </tbody>
//                 </table>
//               </div>
//             </Form>
//           </div>

//           <CategoryDetails show={modaleye} onHide={() => setModaleye(false)} />

//           <div className="pagination-div">
//             <CustomPaginate
//               currentPage={currentPage}
//               totalPages={products?.totalPages}
//               handlePageChange={handlePageChange}
//             />
//           </div>

//           <View_all_varients_offcanvas
//             show={varientsoffcanvas}
//             variantData={variantData}
//             getVariantProducts={getVariantProducts}
//             onHide={() => setVarientsoffcanvas(false)}
//             placement="top"
//           />

//           <VeiwEditModal
//             show={modalShowedit}
//             getAllProducts={getAllProducts}
//             varientType={varientType}
//             productId={productId}
//             type={type}
//             onHide={() => setModalShowedit(false)}
//           />

//           <Mrp_modal show={modalShow} onHide={() => setModalShow(false)} />
//         </div>
//       </section>
//     </>
//   );
// };

// export default Ordertable;
