import React, { useContext } from "react";
import { useState, useEffect } from "react";
import Header from "../../../Header/Header";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { event } from "jquery";
// import EventAddModal from "./EventAddModal/EventAddModal";
import EventEditModal from "./EventAddModal/EventEditModal";
// import { getEventsApi, eventDelete } from "../../../../../utils/apis/calendar";
import { Context } from "../../../../../utils/context";
import { getEvents } from "../../../../../utils/apis/events/event";
import EventCard from "./EventCard/EventCard";
import { getSellerCalendar } from "../../../../../utils/apis/seller/calendar/index";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
const Calendars = () => {
  const { IMG_URL } = useContext(Context);
  const [eventEditShow, setEventEditShow] = useState(0);
  const [eventData, setEventData] = useState("");

  const [events, setEvents] = useState([]);
  const [taskDead, setTaskDead] = useState([]);
  const [calendar, setCalender] = useState([]);

  const [selectedDate, setSelectedDate] = useState();

  const GetEditData = async () => {
    const res = await getSellerCalendar(
      moment(selectedDate).format("YYYY-MM-DD")
    );
    setCalender(res?.data?.data);
    setEvents(res?.data?.events);
    setTaskDead(res?.data?.tasks);
  };

  const handleSelectSlot = (slotInfo) => {
    const selectedDate = slotInfo.start;
    setSelectedDate(selectedDate); // Store the selected date in state
    console.log("Selected Date:", selectedDate);
  };

  const eventStyleGetter = (event) => {
    const backgroundColor = event.color; // Use the color from the event data
    return {
      style: {
        backgroundColor,
        color: "white",
        borderRadius: "5px",
        border: "none",
        display: "block",
      },
    };
  };

  const EventWithImage = ({ event }) => (
    <div>
      {event.image && (
        <img
          src={IMG_URL + event.image}
          style={{
            width: "30px", 
            height: "30px", 
            marginRight: "5px",
            marginBottom: "4px",
            borderRadius: "50%", 
            objectFit: "cover",
          }}
          alt="Event"
        />
      )}

      <marquee>
        <sub>{event.title}</sub>
      </marquee>
    </div>
  );

  const formatDate = (date) => {
    const options = {
      weekday: "short", // "Mon"
      year: "numeric", // "2024"
      month: "short", // "Nov"
      day: "numeric", // "26"
    };
    return date.toLocaleDateString("en-US", options).replace(/,/g, "");
  };

  useEffect(() => {
    GetEditData();
  }, [selectedDate]);

  return (
    <>
      <div className="main-advancedashboard">
        <div className="Map-Section">
          <Row className="justify-content-between">
            <Col xxl={8} lg={8} md={12} className="my-3">
              <Calendar
                localizer={localizer}
                events={calendar}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 800 }}
                onSelectSlot={handleSelectSlot}
                selectable={true}
                eventPropGetter={eventStyleGetter}
                dayPropGetter={(date) => {
                  const isToday =
                    new Date().toDateString() === date.toDateString();

                  if (isToday) {
                    return {
                      className: "today-day",
                      style: {
                        backgroundColor: "#6fe379",
                        color: "black",
                      },
                    };
                  }

                  // if(selectedDate){
                  //   console.log(formatDate(selectedDate));

                  //   const isSelected =
                  //   formatDate(selectedDate) === date.toDateString();
                  //   if (isSelected) {
                  //     return {
                  //       className: "today-day",
                  //       style: {
                  //         backgroundColor: "#83dcf7", // Highlight today's date with a color
                  //         color: "black",
                  //       },
                  //     };
                  //   }
                  // }

                  return {};
                }}
                components={{
                  event: EventWithImage,
                }}
                views={["month"]}
              />
            </Col>

            <Col xxl={4} lg={4} md={5} className="my-3">
              <EventCard events={events} taskDead={taskDead} />
            </Col>
          </Row>
        </div>
      </div>

     
    </>
  );
};

export default Calendars;
