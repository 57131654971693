// NewNotification.jsx
import React, { useContext } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./NewNotification.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Context } from "../../../../../utils/context";
import { Link } from "react-router-dom";

const BTBEvents = ({ shownoti, handleClosenoti,events }) => {
  const quotationData = [
    {
      companyName: "Zungei Pvt. Ltd.",
      timeAgo: "1 minutes ago",
      imageSrc: "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png",
    },
    {
      companyName: "Profcyma Pvt. Ltd.",
      timeAgo: "5 minutes ago",
      imageSrc: "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png",
    },
  ];

  const {IMG_URL} = useContext(Context);

  return (
    <>
      <div className="b2b-buyer-notificaton">
        <Offcanvas
          show={shownoti}
          onHide={handleClosenoti}
          backdrop="static"
          placement="end"
          className="b2b-buyer-notificaton-off"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Events</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="my-3 nav-div">
              <div>
                {events?.map((value, index) => (
                  <div className="card">
                    <div className="img-div">
                      {/* D:\React Projects\netpurti_seller_admin3\public\assest\images\Seller_panel\AffilateDashboard\Images\profile.png */}
                      <img className="cardimg" src={IMG_URL + value?.image} />
                    </div>
                    <div className="cardbody">
                      <p className="datep">
                        {value?.start} - {value?.end}
                      </p>
                      <h6 className="cardtitle">{value?.title}</h6>
                      <p className="like">
                        {" "}
                        <span>Type : {value?.event_type}</span>
                        <span className="ps-2 pe-2">
                          |{" "}
                          {value?.event_type === "Online" ? (
                            <Link target={new Date(value?.end) <= new Date() ? "" : "_blank"}  to={new Date(value?.end) <= new Date() ? '' : value?.event_link} className="btn btn-info">View Link</Link>
                          ) : (
                            value?.event_address
                          )}
                        </span>
                        <span></span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default BTBEvents;
