import React, { useState } from 'react'
import Tasktable from './InHouseTable/InHouseTable'
import Taskstock from './InHouseStock/InHouseStock'

const Orders = () => {

  

  
  return (
    <>


    
      <Taskstock/> 
      <Tasktable/>

    </>
  )
}

export default Orders