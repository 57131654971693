import React, { useState, useEffect, useContext } from "react";
import "./Sidebar_seller_dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "react-bootstrap/Accordion";
import { Link, useLocation } from "react-router-dom";
import {
  faAngleRight,
  faArrowRight,
  faCar,
  faCarSide,
  faCheck,
  faCrop,
  faDriversLicense,
  faFile,
  faFileLines,
  faGlobe,
  faIdCard,
  faInfo,
  faPhoneVolume,
  faSms,
  faStore,
  faTentArrowTurnLeft,
  faTicket,
  faTractor,
  faWheatAwn,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  faPage4,
  faPagelines,
  faPaypal,
  faThemeisle,
} from "@fortawesome/free-brands-svg-icons";
import { Context } from "../../../../utils/context";
import { getThemeSections } from "../../../../utils/apis/seller/Mypackages/MyPackages";

const Sidebar_seller_dashboard = () => {
  const [activeKey, setActiveKey] = useState("0");
  const location = useLocation();
  const [show, setShow] = useState(false);

  const [close, setClose] = useState(false);
  const [activeLink, setActiveLink] = useState(""); // Add state for active link

  const toggleSidebar = () => {
    setSidebar(!sidebar);

    setOuterSidebar(true);
    setInnerSidebar(true);
  };

  const {
    sidebar,
    setSidebar,
    outerSidebar,
    setOuterSidebar,
    innerSidebar,
    setInnerSidebar,
    sellerDomain,
    domainData,
    setDomainData,
  } = useContext(Context);

  useEffect(() => {
    // Extract the pathname from the location object
    const { pathname } = location;

    // Define a function to determine the active key based on the pathname
    const determineActiveKey = () => {
      switch (pathname) {
        case "/seller/seller-dashboard/":
          return "0";
        case "/seller/seller-dashboard/b2b-products":
          return "1";
        case "/seller/seller-dashboard/orders":
          return "2";
        case "/seller/seller-dashboard/b2b-api-documentation":
          return "3";
        case "/seller/seller-dashboard/wallet":
          return "5";
        case "/seller/seller-dashboard/mywebsite":
          return "6";
        case "/seller/seller-dashboard/advertisement":
          return "17";
        case "/seller/seller-dashboard/sidebar_component":
          return "13";
        case "/seller/seller-dashboard/query":
          return "14";
        case "/seller/seller-dashboard/looking-for":
          return "15";
        case "/seller/seller-dashboard/contact-us":
          return "16";
        case "/seller/seller-dashboard/info-website":
          return "30";
        case "/seller/seller-dashboard/package":
          return "31";
        case "/seller/seller-dashboard/inquiry-websites":
          return "32";
        case "/seller/seller-dashboard/my-package":
          return "33";
        case "/seller/seller-dashboard/my-package-themes":
          return "33";

        default:
          return "0"; // Default active key
      }
    };

    // Set the active key based on the current pathname
    setActiveKey(determineActiveKey());
  }, [location]);

  return (
    <>
      <section
        className={`Sidebar_seller_dashboard ${
          sidebar ? "scale-down-hor-left" : "scale-up-hor-left"
        }`}
      >
        <div className="sidebar-holder">
          <div className="collapse-toggle-btn-holder"></div>
          <div className="logo-image-main">
            <div className="collapse-toggle-btn" onClick={toggleSidebar}>
              <FontAwesomeIcon
                className={`toggle ${sidebar ? "rotate-180" : "rotate-0"}`}
                icon={faAngleRight}
              />
            </div>

            <Link to="/seller/seller-dashboard">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assest/images/Seller_panel/seller-image/logo/netpurti_logo_sidebar.png"
                }
                className={`logo-img ${sidebar ? "d-none" : "d-block"}`}
                alt="..."
              />
            </Link>
            <Link to={"/advanceDashboard"}>
              <img
                className={`logo2 mx-2 ${sidebar ? "d-block" : "d-none"}`}
                src={
                  process.env.PUBLIC_URL +
                  "/assest/images/Seller_panel/seller-image/logo/netpurtilogosmall.png"
                }
              />
            </Link>
          </div>

          {/* <div className="brown-border"></div> */}

          <div className="tabs-main-secttt">
            <Accordion
              activeKey={activeKey}
              onSelect={(key) => setActiveKey(key)}
            >
              {/* Dashboard */}
              <Accordion.Item className="no-arrow" eventKey="0">
                <Link to={"/seller/seller-dashboard/"}>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Dashboard</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon="fa-solid fa-house" />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        Dashboard
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                {/* Inventory */}
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Inventory</Tooltip>}
                >
                  <Accordion.Header>
                    <span className="ico-span">
                      <FontAwesomeIcon icon="fa-solid fa-cube" />
                    </span>
                    <span style={{ display: sidebar ? "none" : "block" }}>
                      Inventory
                    </span>
                  </Accordion.Header>
                </OverlayTrigger>

                <Accordion.Body className="inner-dropp">
                  {/* B2B Products */}
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>B2B Products</Tooltip>}
                  >
                    <div
                      className={`inner-menu-div ${
                        activeLink === "/seller/seller-dashboard/b2c-products"
                          ? ""
                          : "active"
                      }`}
                    >
                      <Link
                        className="inner-menu"
                        to={"/seller/seller-dashboard/b2b-products"}
                      >
                        <div className="drippp">
                          <span className="inner-menu-icc">
                            <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                          </span>
                          <span
                            style={{
                              display: sidebar ? "none" : "block",
                            }}
                          >
                            B2B Products
                          </span>
                          <FontAwesomeIcon
                            icon="fa-solid fa-angle-right"
                            className="next-fafaf"
                          />
                        </div>
                      </Link>

                      {/* {show && (
                    <div className="sublistt-div">
                      <div className="sub-men-div">
                        <Link to="/#" className="sublist-menu">
                          <span
                            style={{
                              display: sidebar ? "none" : "block",
                            }}
                          >
                            Active Products
                          </span>
                        </Link>
                      </div>
                      <div className="sub-men-div">
                        <Link to="/#" className="sublist-menu">
                          <span
                            style={{
                              display: sidebar ? "none" : "block",
                            }}
                          >
                            {" "}
                            Inactive Products
                          </span>
                        </Link>
                      </div>
                      <div className="sub-men-div">
                        <Link to="/#" className="sublist-menu">
                          <span
                            style={{
                              display: sidebar ? "none" : "block",
                            }}
                          >
                            {" "}
                            Drafted Products
                          </span>
                        </Link>
                      </div>
                    </div>
                  )} */}
                    </div>
                  </OverlayTrigger>

                  {/* B2C Products */}
                  {/* <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>B2C Products</Tooltip>}
                  >
                    <div
                      className={`inner-menu-div ${activeLink === "" ? "" : "active"
                        }`}
                    >
                      <Link className="inner-menu">
                        <div className="drippp">
                          <span className="inner-menu-icc">
                            <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                          </span>
                          <span
                            style={{
                              display: sidebar ? "none" : "block",
                            }}
                          >
                            B2C Products
                          </span>
                        </div>
                      </Link>
                    </div>
                  </OverlayTrigger> */}

                  {/*  My Website */}
                  {/* <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> My Website</Tooltip>}
                  >
                    <div
                      className={`inner-menu-div ${
                        activeLink === "" ? "" : "active"
                      }`}
                    >
                      <Link className="inner-menu">
                        <div className="drippp">
                          <span className="inner-menu-icc">
                            <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                          </span>
                          <span
                            style={{
                              display: sidebar ? "none" : "block",
                            }}
                          >
                            My Website
                          </span>
                        </div>
                      </Link>
                    </div>
                  </OverlayTrigger> */}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                {/* Order */}
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Order</Tooltip>}
                >
                  <Accordion.Header>
                    <span className="ico-span">
                      <FontAwesomeIcon icon="fa-solid fa-bag-shopping" />
                    </span>
                    <span style={{ display: sidebar ? "none" : "block" }}>
                      Orders
                    </span>
                  </Accordion.Header>
                </OverlayTrigger>

                <Accordion.Body className="inner-dropp">
                  {/*  B2B Orders */}
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> B2B Orders</Tooltip>}
                  >
                    <div
                      className={`inner-menu-div ${
                        activeLink === "/seller/seller-dashboard/orders"
                          ? ""
                          : "active"
                      }`}
                    >
                      <Link
                        className="inner-menu"
                        to={"/seller/seller-dashboard/orders"}
                      >
                        <div className="drippp">
                          <span className="inner-menu-icc">
                            <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                          </span>
                          <span
                            style={{
                              display: sidebar ? "none" : "block",
                            }}
                          >
                            B2B Orders
                          </span>
                        </div>
                      </Link>
                    </div>
                  </OverlayTrigger>

                  {/*  B2C Orders */}
                  {/* <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> B2C Orders</Tooltip>}
                  >
                    <div
                      className={`inner-menu-div ${
                        activeLink === "" ? "" : "active"
                      }`}
                    >
                      <Link className="inner-menu">
                        <div className="drippp">
                          <span className="inner-menu-icc">
                            <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                          </span>
                          <span
                            style={{
                              display: sidebar ? "none" : "block",
                            }}
                          >
                            B2C Orders
                          </span>
                        </div>
                      </Link>
                    </div>
                  </OverlayTrigger> */}
                </Accordion.Body>
              </Accordion.Item>

              {/* API Documentation */}
              {/* <Accordion.Item className="no-arrow" eventKey="3" >
                <Link to="/seller/seller-dashboard/b2b-api-documentation">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>API Documentation</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faFile} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        API Documentation
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item> */}

              {/* Wallet */}
              <Accordion.Item className="no-arrow" eventKey="5">
                <Link to="/seller/seller-dashboard/wallet">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Wallet</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon="fa-solid fa-wallet" />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        Wallet
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item>

              {/* My websites start */}

              {/* Theme*/}
              <Accordion.Item className="no-arrow" eventKey="31">
                <Link to="/seller/seller-dashboard/package">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Packages</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faCalendarDays} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        Packages
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item>

              <Accordion.Item className="no-arrow" eventKey="33">
                <Link to="/seller/seller-dashboard/my-package">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>MyPackage</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faThemeisle} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        MyPackages
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item>

              {/*  My Websitess */}
              {domainData?.theme?.theme_category?.name === "Ecommerce" && (
                <Accordion.Item className="no-arrow" eventKey="6">
                  <Link to="/seller/seller-dashboard/mywebsite">
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip> My Websites</Tooltip>}
                    >
                      <Accordion.Header>
                        <span className="ico-span">
                          <FontAwesomeIcon icon={faGlobe} />
                        </span>
                        <span style={{ display: sidebar ? "none" : "block" }}>
                          My Websites
                        </span>
                      </Accordion.Header>
                    </OverlayTrigger>
                  </Link>
                </Accordion.Item>
              )}

              {/* Info Websites*/}
              {domainData?.theme?.theme_category?.name === "Informative" && (
                <Accordion.Item className="no-arrow" eventKey="30">
                  <Link to="/seller/seller-dashboard/info-website">
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip> Info Websites</Tooltip>}
                    >
                      <Accordion.Header>
                        <span className="ico-span">
                          <FontAwesomeIcon icon={faInfo} />
                        </span>
                        <span style={{ display: sidebar ? "none" : "block" }}>
                          Info Websites
                        </span>
                      </Accordion.Header>
                    </OverlayTrigger>
                  </Link>
                </Accordion.Item>
              )}

              {/* Inquiry Websites*/}
              {domainData?.theme?.theme_category?.name === "Inquiry" && (
                <Accordion.Item className="no-arrow" eventKey="32">
                  <Link to="/seller/seller-dashboard/inquiry-websites">
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip> Inquiry Websites</Tooltip>}
                    >
                      <Accordion.Header>
                        <span className="ico-span">
                          <FontAwesomeIcon icon={faPhoneVolume} />
                        </span>
                        <span style={{ display: sidebar ? "none" : "block" }}>
                          Inquiry Websites
                        </span>
                      </Accordion.Header>
                    </OverlayTrigger>
                  </Link>
                </Accordion.Item>
              )}

              {/*Advertisement*/}
              <Accordion.Item className="no-arrow" eventKey="17">
                <Link to="/seller/seller-dashboard/advertisement">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Advertisement</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon="fa-solid fa-rectangle-ad" />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        Advertisement
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item>

              {/*Message*/}
              {/* <Accordion.Item className="no-arrow" eventKey="18">
                <Link to="">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Message</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faSms} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        Message
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item> */}

              <Accordion.Item className="no-arrow" eventKey="18">
                <Link to="/seller/seller-dashboard/seller-calendar">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Calendar</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faCalendarDays} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        Calendar
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item>

              <Accordion.Item className="no-arrow" eventKey="18">
                <Link to="/seller/seller-dashboard/seller-events">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Events</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faCalendarDays} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        Events
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item>

              {/*Transport*/}
              {/* <Accordion.Item className="no-arrow" eventKey="19">
                <Link to="">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Transport</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faCar} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        Transport
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item> */}

              {/*RTO*/}
              {/* <Accordion.Item className="no-arrow" eventKey="20">
                <Link to="">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> RTO</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faDriversLicense} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        RTO
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item> */}

              {/* Refund & Return*/}
              {/* <Accordion.Item className="no-arrow" eventKey="21">
                <Link to="">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Refund & Return</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faTentArrowTurnLeft} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        {" "}
                        Refund & Return
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item> */}

              {/* Compliant*/}
              {/* <Accordion.Item className="no-arrow" eventKey="22">
                <Link to="">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Compliant</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faFileLines} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        Compliant
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item> */}

              {/* Checker*/}
              {/* <Accordion.Item className="no-arrow" eventKey="23">
                <Link to="">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Checker</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        Checker
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item> */}

              {/* Payment*/}
              {/* <Accordion.Item className="no-arrow" eventKey="24">
                <Link to="">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Payment</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon className="me-2" icon={faPaypal} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        Payment
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item> */}

              {/* Coupon*/}
              {/* <Accordion.Item className="no-arrow" eventKey="25">
                <Link to="">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Coupon</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon className="me-2" icon={faIdCard} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        Coupon
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item> */}

              {/*  Farmer | Add Inventory*/}
              {/* <Accordion.Item className="no-arrow" eventKey="26">
                <Link to="">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Farmer | Add Inventory</Tooltip>}
                  >
                    <Accordion.Header className="text-start">
                      <span className="ico-span">
                        <FontAwesomeIcon className="me-2" icon={faTractor} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        Farmer | Add Inventory
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item> */}

              {/* Farmer | Crops Planing*/}
              {/* <Accordion.Item className="no-arrow" eventKey="27">
                <Link to="">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Farmer | Crops Planing</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon className="me-2" icon={faWheatAwn} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        Farmer | Crops Planing
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item> */}

              {/* My Purchase*/}
              {/* <Accordion.Item className="no-arrow" eventKey="28">
                <Link to="">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> My Purchase</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon className="me-2" icon={faTicket} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        My Purchase
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item> */}

              {/*  My Store*/}
              {/* <Accordion.Item className="no-arrow" eventKey="29">
                <Link to="">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> My Store</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon className="me-2" icon={faStore} />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        My Store
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item> */}

              {/*  3P Team Support*/}
              {/* <Accordion.Item className="no-arrow" eventKey="40">
                <Link to="">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> 3P Team Support</Tooltip>}
                  >
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon
                          className="me-2"
                          icon={faPhoneVolume}
                        />
                      </span>
                      <span style={{ display: sidebar ? "none" : "block" }}>
                        3P Team Support
                      </span>
                    </Accordion.Header>
                  </OverlayTrigger>
                </Link>
              </Accordion.Item> */}

              {/* My webaites end */}

              {/* Category starts */}

              {/* Category ends */}
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sidebar_seller_dashboard;
