// NewNotification.jsx
import React, { useContext } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "./NewNotification.css"
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Context } from '../../../../utils/context';


const NewNotification = ({ shownoti, handleClosenoti,notifications }) => {


    const {timeAgo} = useContext(Context);
    const quotationData = [
        {
            companyName: 'Zungei Pvt. Ltd.',
            timeAgo: '1 minutes ago',
            imageSrc: "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png"
        },
        {
            companyName: 'Profcyma Pvt. Ltd.',
            timeAgo: '5 minutes ago',
            imageSrc: "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png"
        },
        
    ];
    return (
        <>
            <div className='b2b-buyer-notificaton'>
                <Offcanvas show={shownoti} onHide={handleClosenoti} backdrop="static" placement='end' className="b2b-buyer-notificaton-off">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Notifications</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        
                        <div className='my-3 nav-div'>
                            
                                        <div>
                                            {notifications.map((quotation, index) => (
                                                <div className='flex-div' key={index}>
                                                    <div className='img-div'>
                                                        
                                                        {/* <img
                                                            className="profile-pic me-2"
                                                            src={process.env.PUBLIC_URL + quotation.imageSrc}
                                                            alt="Logo"
                                                        /> */}
                                                        <div className='dot' />
                                                    </div>
                                                    <div>
                                                        {/* <span className='from-text'>Quotation Received from </span> */}
                                                        <span className='com-name'>{quotation.message}</span>
                                                        <p className='grey-text'>{timeAgo(quotation.createdAt)}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    
                        </div>

                    </Offcanvas.Body>
                </Offcanvas>
            </div>

        </>
    );
};

export default NewNotification;
