import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddNoteModal from "../../common-components/AllModal/Add_note_modal/AddNoteModal";
import useRazorpay from "react-razorpay";
import { useForm } from "react-hook-form";
import { postData } from "../../../../utils/api";
import Cookies from 'js-cookie';
const Delivery_options = ({
  handlePrevious,
  handleNext,
  buyNowData,
  address_id,
  quantity,
  setQuantity,
  setAmount,
  orderDetail,
  IMG_URL,
  setValue,
  getValues,
  reset,
  addressId,
}) => {
  const [delivery_insurance, setDelivery_insurance] = useState(false);
  const [checker, setChecker] = useState(false);
  // const [quantity, setQuantity] = useState(buyNowData?.min_quantity)
  const [total_amount, setTotalAmount] = useState(
    quantity * buyNowData?.selling_price
  );
  console.log(
    "checker",
    checker,
    "delivery_insurance",
    delivery_insurance,
    "address_id",
    address_id
  );

  const handleQuantityChange = async (type) => {
    console.log(getValues("selling_price"), "llll");
    if (type === "minus" && getValues("quantity") > getValues("min_quantity")) {
      const currentQuantity = await getValues("quantity");
      const currentTotalSelling = await getValues("total_selling");
      const currentMrp = await getValues("total_mrp");
      const currentTax = await getValues("tax");
      const sellingPrice = await getValues("selling_price");
      const mrp = await getValues("mrp");

      await setValue("quantity", currentQuantity - 1);
      await setValue("total_selling", currentTotalSelling - sellingPrice);
      await setValue("total_mrp", currentMrp - mrp);
      await setValue("total_tax", (await getValues("total_tax")) - currentTax);

      // Calculate discount based on the updated values
      const updatedTotalMrp = currentMrp - mrp;
      const updatedTotalSelling = currentTotalSelling - sellingPrice;
      const discount = updatedTotalMrp - updatedTotalSelling;

      await setValue("discount", discount);
      await setValue("total", updatedTotalSelling + getValues("total_tax"));
      console.log(discount);
      reset(getValues());
    } else if (type === "plus") {
      const currentQuantity = await getValues("quantity");
      const currentTotalSelling = await getValues("total_selling");
      const currentMrp = await getValues("total_mrp");
      const currentTax = await getValues("tax");
      const sellingPrice = await getValues("selling_price");
      const mrp = await getValues("mrp");

      await setValue("quantity", currentQuantity + 1);
      await setValue("total_selling", currentTotalSelling + sellingPrice);
      await setValue("total_mrp", currentMrp + mrp);
      await setValue("total_tax", (await getValues("total_tax")) + currentTax);

      // Calculate discount based on the updated values
      const updatedTotalMrp = currentMrp + mrp;
      const updatedTotalSelling = currentTotalSelling + sellingPrice;
      const discount = updatedTotalMrp - updatedTotalSelling;
      console.log(discount);
      await setValue("discount", discount);
      await setValue("total", updatedTotalSelling + getValues("total_tax"));
      reset(getValues());
    }
  };

  const [modalShow, setModalShow] = useState(false);
  const hideModalWithDelay = () => {
    setTimeout(() => {
      setModalShow(false);
      // navigate("/");
    }, 3000);
  };

  const [Razorpay, openRazorpay] = useRazorpay();
  const [razorid, setRazorId] = useState();
  const [discount, setDiscount] = useState(0);
  const handleRazorpay = async () => {
    const storedValue = getValues("total");
    const options: RazorpayOptions = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(storedValue) * 100,
      currency: "INR",
      name: "Net Purti Pay",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        finalFunction(res.razorpay_payment_id);
        console.log(res, "ttttttttttttttttttttttttttttt");
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  const navigate = useNavigate();
  const finalFunction = async (razor = null) => {
    // const data = {
    //     address_id: address_id,

    //     seller_id: orderDetail?.seller_id,
    //     // discount: getValues("discount"),
    //     payment_mode: razor ? "online" : "cod",
    //     payment_id: razorid,
    //     delivery_insurance: delivery_insurance,
    //     checker: checker,

    // };
    setValue("address_id", address_id);
    setValue("payment_mode", razor ? "online" : "cod");
    setValue("payment_id", razor);
    setValue("delivery_insurance", delivery_insurance);
    setValue("checker", checker);

    let data = {
      address_id: address_id,
      payment_mode: razor ? "online" : "cod",
      payment_id: razor,
      delivery_insurance: delivery_insurance,
      checker: checker,
    };

    let affiliate = "";
    if (Cookies.get("a_id")) {
      affiliate = JSON.parse(Cookies.get("a_id"));
    }

    if (affiliate?.a_id) {
      data.a_id = affiliate?.a_id;
      data.p_id = affiliate?.p_id;
    }



    const res = await postData("/btb/order-process/buy-order", getValues());
    if (res?.success) {
      setModalShow(true);
      hideModalWithDelay();
      navigate("/b2bbuyer/order");
    }
  };

  return (
    <>
      <section className="stepp-inner-main">
        <div className="delevidiv">
          <div className="row">
            <div className="col-lg-9">
              <div className="cardflex">
                <div className="img-div">
                  <img src={IMG_URL + getValues("thumbnail")} />
                </div>
                <div className="cont-div my-auto">
                  <p className="nnnn">
                    {/* Armchair New Arrival Promotion Fabric Material Adjustable Leisure Single Power Electric Relax */}
                    {orderDetail?.product_title}
                  </p>
                  <p className="mimodd">
                    Min. order:<span>{getValues("min_quantity")} Pieces</span>
                  </p>
                </div>
              </div>

              <hr></hr>

              <div className="row">
                <div className="col-6">
                  <p className="esttdelby">
                    Estimated delivery by :<span> 17 April 2024</span>
                  </p>
                </div>
                <div className="col-6">
                  <p className="esttdelby" onClick={() => setModalShow(true)}>
                    Any note for supplier ?
                    <span className="addnott"> Add note</span>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="d-flex mt-4">
                  <Form.Group className="mb-3">
                    <Form.Check
                      label="Need delivery insurance?"
                      name="delivery_insurance"
                      inline
                      // checked={getValues("delivery_insurance")}
                      onChange={(e) => setDelivery_insurance(e.target.checked)}
                    />
                    <Form.Check
                      label="Need Checker?"
                      name="checker"
                      inline
                      // checked={getValues("checker")}
                      onChange={(e) => setChecker(e.target.checked)}
                    />
                  </Form.Group>
                </div>
                {/* <div className='radio-box my-4'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <Form.Check
                                                label="Project Safety Charges"
                                                className='charges-label'
                                                type='radio'
                                                name='name'

                                            />
                                            <span>( it incl. 20% of total charges)</span>
                                        </div>
                                        <div className='col-md-4'>

                                            <Form.Check
                                                label="Product Design"
                                                type='radio'
                                                name='name'
                                                className='charges-label'
                                            />
                                            <span>( it incl. 20% of total charges)</span>
                                        </div>
                                        <div className='col-md-4'>
                                            <Form.Check
                                                label="Both"
                                                type='radio'
                                                name='name'
                                                className='charges-label'
                                            />
                                            <span>( it incl. 20% of total charges)</span>
                                        </div>
                                    </div>


                                </div> */}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="piecce-box">
                {/* <p className='colll'>Color : <span>Olieve Green</span></p> */}
                <p className="colll">₹ {getValues("selling_price")} /piece</p>

                <div className="d-flex countt">
                  <div
                    className="minus-box"
                    onClick={() => handleQuantityChange("minus")}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-minus" />
                  </div>
                  <p className="countnumbb">{getValues("quantity")}</p>
                  <div
                    className="minus-box"
                    onClick={() => handleQuantityChange("plus")}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-plus" />
                  </div>
                </div>

                <p className="totatllamt">₹ {getValues("total_selling")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="save-btn-div ">
          <button className="back-btn" onClick={handlePrevious}>
            <FontAwesomeIcon icon="fa-solid fa-arrow-left-long" /> &nbsp; Back
          </button>
          <button
            className="continue-btn"
            type="button"
            // onClick={handleNext}
            onClick={handleRazorpay}
          >
            Continue
            <FontAwesomeIcon
              className="next-fafa"
              icon="fa-solid fa-arrow-right-long"
            />
          </button>
        </div>
      </section>
      <AddNoteModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Delivery_options;
